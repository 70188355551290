import FlashMessages from '@/core/services/FlashMessages'

export default class Validator {
  /**
   * Apply the validation rules on the provided form data.
   *
   * @param {any} form
   * @param {any} error
   */
  apply (form, error = 'default') {
    if (this[error] === undefined) {
      return {}
    }

    const validators = this[error]()

    return Object.keys(validators).reduce((carry, key) => {
      const value = form[key]
      const item = validators[key]

      if (typeof item === 'string') {
        return { ...carry, [key]: [item] }
      }

      if (Array.isArray(item)) {
        return { ...carry, [key]: this._applyValidators(item, value) }
      }

      if (!item.validator(value)) {
        return { ...carry, [key]: [item.message(value)] }
      }

      return carry
    }, {})
  }

  /**
   * Apply the validation rules on the provided form data.
   *
   * @param {any} form
   * @param {any} error
   */
  _applyValidators (item, value) {
    return item.filter(v => !v.validator(value)).map(v => v.message(value))
  }

  /**
   * Global error handling definitions.
   */
  LimitExceededException () {
    return {
      any: {
        validator: value => false,
        message: value => FlashMessages.warning('Please wait before performing more requests.')
      }
    }
  }
}
