export default {

  namespaced: true,

  state: {
    //
  },

  mutations: {
    updateCrlpData (state, data) {
      state.currentMerchant = data
    }
  },

  actions: {

    /**
     * Match `retailer` name against active domains to load data.
     *
     * @param {string} retailer the url for retailer passed in path
     */
    async loadCrlpData ({ commit, dispatch, rootState }, retailer) {
      await dispatch('loadActiveDomains', null, { root: true })

      const name = decodeURIComponent(retailer)
      const { merchant } = rootState.activeDomains.find(d => d.merchant.name === name)

      return commit('updateCrlpData', merchant)
    }
  }

}
