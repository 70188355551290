<template>
  <span>
    <span class="tooltip-hoverable hover-trigger">
      <slot name="tooltip-label"/>
      <div class="tooltip-block hover-target">
        <slot name="on-hover" />
      </div>
    </span>
  </span>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>
.tooltip-hoverable
  position: relative

.tooltip-block
  position: absolute
  background-color: white
  width: 186px
  left: -83px
  top: 27px
  border-radius: 4px
  box-shadow: 0px 2px 8px rgba(54, 54, 54, 0.1)
  text-align: center
  padding: 11px 9px

  font-weight: 600
  font-size: 12px
  line-height: 18px

  color: #7A7A7A
  z-index: 10

  &:before
    content: ''
    width: 0
    height: 0
    position: absolute
    border-left: 8px solid transparent
    border-right: 8px solid transparent
    border-bottom: 8px solid white
    top: -8px
    right: 85px

  @media (max-width: 425px)
    left: 0
    border-top-left-radius: 0
    &:before
      left: 0

</style>
