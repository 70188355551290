<template>
  <div class="tabs">
  <ul>
    <slot></slot>
  </ul>
</div>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>
@media screen and (max-width: 1215px)
  .tabs
      ul
        justify-content: center
        flex-shrink: 1
        flex-wrap: wrap
</style>
