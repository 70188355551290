
const events = [
  {
    name: 'subscribe',
    fn ({ type }, { community }) {
      if (!/^community\/updateResource$/gi.test(type)) {
        return
      }

      // Filters out processed codes.
      const resources = community.resources
        .map((r) => {
          r.codes = r.codes.filter(c => c.approved === true || c.approved === false)

          return r
        })
        .filter(r => r.codes.length)

      // Caches codes to local storage, on next reload it will merge codes from
      // local storage with the received codes from APIs.
      localStorage.setItem('pouch.community.codes', JSON.stringify(resources))
    }
  }
]

export default store => events.forEach(({ name, fn }) => store[name](fn))
