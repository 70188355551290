<template>
  <li :class="{ 'is-active': active }" class="tab" @click="$emit('selected')">
    <slot></slot>
  </li>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.tab
  background-color: #FFFFFF

@media screen and (max-width: 1215px)
  .tab
    margin-right: 8px
    margin-bottom: 8px
</style>
