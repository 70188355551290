import Uid from '@/core/services/Uid'
import Once from '@/core/services/Once'
import Dispatcher from '@/core/events/Dispatcher'
import * as Analytics from '@/core/services/Analytics'
import FlashMessages from '@/core/services/FlashMessages'
import { getReferrerFromEnterData } from '@/core/services/TrackingData'

/**
 * Notify the user of the application going offline.
 */
Dispatcher.touch('app.goneOffline').addListener((event) => {
  Once.in(5 * 60, () => FlashMessages.warning('The application is now runnning in offline mode.'))
})

Dispatcher.touch('app.searchFormSubmitted').addListener((event) => {
  Analytics.mixpanel.identify(Uid.getOrNew())
  Analytics.mixpanel.event('SiteSearch', {
    method: 'submission',
    target: event.target,
    query: event.query,
    route: event.route.fullPath,
    dataSource: 'web'
  })
})

Dispatcher.touch('app.searchSuggestionClicked').addListener((event) => {
  Analytics.mixpanel.identify(Uid.getOrNew())
  Analytics.mixpanel.event('SiteSearch', {
    method: 'suggestion',
    target: event.target,
    query: event.query,
    route: event.route.fullPath,
    dataSource: 'web'
  })
})

/**
 * Deal events analytics.
 */
Dispatcher.touch('app.dealOpened').addListener((event) => {
  Analytics.mixpanel.identify(Uid.getOrNew())
  Analytics.mixpanel.event('DealClick', {
    platform: 'website',
    domain: event.deal.domain,
    code: event.deal.coupon_code,
    type: event.deal.deal_type,
    dataSource: 'web'
  })
})

Dispatcher.touch('app.pouchInstalled').addListener((event) => {
  Analytics.gtm.event('Install')
})

Dispatcher.touch('app.demoOpened').addListener((event) => {
  //
})

/**
 * Other retailer events.
 */
Dispatcher.touch('app.retailerSocialClicked').addListener((event) => {
  Analytics.mixpanel.identify(Uid.getOrNew())
  Analytics.mixpanel.event('RetailerSocialClick', {
    route: event.route.fullPath,
    social: event.social,
    url: event.url,
    dataSource: 'web'
  })
})

Dispatcher.touch('app.messengerAdClicked').addListener((event) => {
  Analytics.mixpanel.identify(Uid.getOrNew())
  Analytics.mixpanel.event('MessengedAdClick', {
    route: event.route.fullPath,
    dataSource: 'web'
  })
})

Dispatcher.touch('app.addToBrowserClicked').addListener((event) => {
  Analytics.mt.event('Test Event', 'userAction:addToBrowserClicked', { route: event.route })
})

/**
 * Router events.
 */
Dispatcher.touch('router.beforeRouteEnter').addListener(({ to, from }) => {
  const ref = getReferrerFromEnterData(from, location.host, document.referrer)
  const loc = location.origin + to.fullPath

  Analytics.ga.set('referrer', ref)
  Analytics.ga.set('location', loc)
  Analytics.ga.pageview()

  Analytics.mt.set('referrer', ref)
  Analytics.mt.set('location', loc)
  Analytics.mt.pageview()
  Analytics.gtm.pageview(to.fullPath)
})
