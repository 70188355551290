/**
 * Cookie service.
 * `Life is like a box of chocolates. You never know what you're gonna get.` - FG
 */
export default {
  removeSpaces (str) {
    const arrayStrings = str.split(' ')
    let finalString = ''

    try {
      for (let i = 0; i < arrayStrings.length; i++) {
        finalString += arrayStrings[i]
      }
      return `${finalString}`
    } catch (err) {
      return err
    }
  },

  /**
   * @returns String
   */
  formatProperty (str) {
    if (str.includes('CognitoIdentityServiceProvider')) {
      const rawArray = str.split('.')
      const property = rawArray[rawArray.length - 1]

      return this.removeSpaces(property)
    }

    return this.removeSpaces(str)
  },

  /**
   * @returns Boolean
   */
  isEncoded (str) {
    return typeof str === 'string' && decodeURIComponent(str) !== str
  },

  /**
   * @returns String
   */
  decode (value) {
    if (this.isEncoded(value)) {
      try {
        const decoded = decodeURIComponent(value.toString())
        return JSON.parse(decoded)
      } catch (err) {
        return err
      }
    }
    return value
  },

  /**
   * Return all cookies as an Object.
   * @return {Object}
   */
  cookieBox () {
    const cookies = document.cookie.split(';')

    /**
     * Organise all the cookies by flavour.
     */
    const box = cookies.reduce((acc, items) => {
      const arrayItems = items.split('=')
      const property = this.formatProperty(arrayItems[0])
      const value = this.decode(arrayItems[1])
      acc[property] = value
      return acc
    }, {})

    return box
  },

  deleteCookies () {
    const cookies = document.cookie.split(';')

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (var i = 0; i < cookies.length; i++) {
      const domain = document.domain || 'joinpouch.com'
      const path = '/'
      document.cookie = cookies[i] + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT' + '; domain=' + domain + '; path=' + path
    }
  }
}
