import Vue from 'vue'

// Package plugins.
import VueCarousel from 'vue-carousel'
import { VueMasonryPlugin } from 'vue-masonry'

// Import all custom plugins.
import Auth from '@/plugins/Auth'
import Config from '@/plugins/Config'
import Browser from '@/plugins/Browser'
import Messages from '@/plugins/Messages'
import Dispatcher from '@/plugins/Dispatcher'
import Mailchimp from '@/plugins/Mailchimp'

Vue.use(VueCarousel)
Vue.use(VueMasonryPlugin)

Vue.use(Auth)
Vue.use(Config)
Vue.use(Browser)
Vue.use(Messages)
Vue.use(Dispatcher)
Vue.use(Mailchimp)

// Register reused vue filters.
Vue.filter('poundarize', val => `£${parseFloat(val / 100).toFixed(2)}`)
Vue.filter('abs', val => Math.abs(val))
