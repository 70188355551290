export default {
  /**
   * The messages container.
   *
   * @var {}
   */
  messages: {
    general: [],
    auth: []
  },

  /**
   * Query all messages.
   *
   * @return {any[]}
   */
  all (type = 'general') {
    return this.messages[type] = this.messages[type].map(m => ({ ...m, shown: true }))
  },

  /**
   * Remove a message.
   *
   * @param {int} id
   * @return {any[]}
   */
  remove (id, type = 'general') {
    return this.messages[type] = this.messages[type].filter(m => m.id !== id)
  },

  /**
   * Remove all shown messages
   */
  removeShown (type = 'general') {
    return this.messages[type] = this.messages[type].filter(m => m.permanent || !m.shown)
  },

  /**
   * Push a success message to the container.
   *
   * @param {string} text
   *@param {boolean} permanent
   */
  success (text, type = '', permanent) {
    this._push('success', type, text, permanent)
  },

  /**
   * Push a warning message to the container.
   *
   * @param {string} text
   */
  warning (text, type = '') {
    this._push('warning', type, text)
  },

  /**
   * Push a danger message to the container.
   *
   * @param {string} text
   */
  danger (text, type = '') {
    this._push('danger', type, text)
  },

  /**
   * Push a info message to the container.
   *
   * @param {string} text
   */
  info (text, type = '') {
    this._push('info', type, text)
  },

  /**
   * Push a message to the container.
   *
   * @private
   * @param {string} level
   * @param {string} message
   */
  _push (level, type, text, permanent) {
    if (!type) {
      type = 'general'
    }

    if (!this.messages[type]) {
      this.messages[type] = []
    }

    this.messages[type].push({
      id: Date.now(),
      level,
      permanent,
      shown: false,
      text
    })
  }
}
