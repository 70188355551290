/**
 * UTM param names.
 *
 * @var {string[]}
 */
export const knownParams = [
  'gclid',
  'gclsrc',
  'utm_campaign',
  'utm_content',
  'utm_creative',
  'utm_id',
  'utm_keyword',
  'utm_matchtype',
  'utm_medium',
  'utm_name',
  'utm_partner',
  'utm_source',
  'utm_term',
  'cjevent'
]

/**
 * Map referrer string patterns to utm param values.
 */
const knownReferrers = [
  {
    pattern: /bing.com/,
    utm_source: 'bing',
    utm_medium: 'organic'
  },
  {
    pattern: /duckduckgo.com/,
    utm_source: 'duckduckgo',
    utm_medium: 'organic'
  },
  {
    pattern: /ecosia.org/,
    utm_source: 'ecosia',
    utm_medium: 'organic'
  },
  {
    pattern: /google.com/,
    utm_source: 'google',
    utm_medium: 'organic'
  },
  {
    pattern: /search.yahoo.com/,
    utm_source: 'yahoo',
    utm_medium: 'organic'
  }
]

function removeKnownUTMs (previousState, nextState) {
  const nextStateKeys = Object.keys(nextState)

  return Object.keys(previousState)
    .filter(key => nextStateKeys.includes(key))
    .reduce((memo, key) => {
      memo[key] = nextState[key]

      return memo
    }, {})
}

function isEmptyState (data) {
  return !Object.keys(data).find(key => knownParams.includes(key))
}

function processUTMs ({ UTMs }, currentState) {
  const defaultFallback = {
    utm_source: 'joinpouch',
    utm_medium: 'direct'
  }

  if (UTMs === null && isEmptyState(currentState)) {
    return defaultFallback
  }

  if (UTMs === null && !isEmptyState(currentState)) {
    return {
      ...currentState
    }
  }

  return {
    ...removeKnownUTMs(currentState, UTMs),
    ...UTMs
  }
}

function isDefaultData (data) {
  return Boolean(data.utm_source === 'joinpouch' && data.utm_medium === 'direct')
}

function isKnownReferrer (referrer) {
  return Boolean(getKnownReferrer(referrer))
}

function getKnownReferrer (referrer) {
  return knownReferrers.find(r => referrer.match(r.pattern))
}

function processReferrer ({ referrer }, currentState) {
  if (!referrer || referrer.includes('joinpouch')) {
    return currentState
  }

  if (isKnownReferrer(referrer)) {
    const knownReferrer = getKnownReferrer(referrer)

    return {
      ...currentState,
      utm_source: knownReferrer?.utm_source,
      utm_medium: knownReferrer?.utm_medium
    }
  }

  if (isDefaultData(currentState)) {
    return {
      ...currentState,
      utm_source: referrer,
      utm_medium: 'organic'
    }
  }

  return currentState
}

/**
 *
 * @param {object} params
 *    type TParams = {
 *        UTMs: {},
 *        referrer: string
 *    }
 * @param {object} state
 * @returns the next state of the localStorage UTMs
 */
export function processTrackingDataState (params, state = {}) {
  return [processUTMs, processReferrer]
    .reduce(
      (processedState, currentFn) => currentFn(params, processedState),
      state
    )
}

export function getReferrerFromEnterData (route, host, referrer) {
  if (referrer === '') {
    return false
  }

  const externalRouteEnter = route.name === null

  // accounts.joinpouch.com, deals.joinpouch.com, joinpouch.com are same host
  const sameHostReferrer = referrer.includes(host)
  const shouldUseReferrer = externalRouteEnter && !sameHostReferrer

  return shouldUseReferrer ? referrer : false
}
