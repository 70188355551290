/**
 * How to use this script:
 *
 * This contains all <head> tag changes that are applied before entering
 * a route for SEO purposes. The configuration is mapped by the route name.
 * It contains changes to the <title> tag and various <meta> tags. You are
 * allowed to access the route object of the destination route.
 *
 * Note that if you create an seo interception for a route, both title and meta
 * fields are required.
 *
 * If no interception is defined for a route, nearest parent route is
 * considered. If there is no such route, the SEO script won't be executed.
 */
export const rules = {

  default: {
    title: 'Never Search for a Voucher Code Again | Pouch',
    description: 'Pouch Is a FREE Shopping Tool That Applies Every Voucher Code Across the Internet in One Click.',
    meta: {
      locale: 'en_GB',
      robots: 'noindex, follow',
      type: 'website',
      site_name: 'Pouch',
      image: 'https://d32v8yls2yz871.cloudfront.net/images/seo/pouch.png',
      'twitter:card': 'summary_large_image',
      'twitter:site': '@JoinPouch',
      'twitter:creator': '@JoinPouch'
    }
  },

  home: {
    title: 'Never Search for a Voucher Code Again | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  bot: {
    title: 'Instant Savings on Facebook Messenger | Pouch',
    description: 'AI-powered deal assistant delivering the best voucher codes to your inbox.'
  },

  uninstall: {
    title: 'Sorry to see you go | Pouch'
  },

  contact: {
    title: 'Contact Us | Pouch',
    description: 'Get in Touch with Pouch. Pouch Is a Free Shopping Tool That Applies Every Voucher Code Across the Internet in One Click.'
  },

  privacypolicy: {
    title: 'Privacy Policy | Pouch',
    description: 'At Pouch, We Put Honesty and Transparency at the Heart of Everything We Do. We Therefore Want to Explain Our Privacy Policy as Clearly and Simply as Possible.',
    meta: {
      robots: 'index, follow'
    }
  },

  tandcs: {
    title: 'Terms and Conditions | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  'tandcs-category': {
    title: 'Terms and Conditions | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  cookiepolicy: {
    title: 'Cookie Policy | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  retailerbenefits: {
    title: 'Retailer Benefits | Pouch',
    description: 'Pouch Is the UK\'s No.1 Tool for Saving Time and Money When Buying Online. We Work with 1000\'s of Retailers to Promote Their Best Discounts to Their Ideal Customers.',
    meta: {
      robots: 'index, follow'
    }
  },

  press: {
    title: 'Press | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  faq: {
    title: 'FAQ | Pouch',
    description: 'Here Are a Few of Our Most Frequently Asked Questions. What Is Pouch? How Does Pouch Work? Is Pouch Free? How Does Pouch Make Money? How Do I Get Started?',
    meta: {
      robots: 'index, follow'
    }
  },

  'faq-category': {
    title: 'FAQ | Pouch',
    description: 'Here Are a Few of Our Most Frequently Asked Questions. What Is Pouch? How Does Pouch Work? Is Pouch Free? How Does Pouch Make Money? How Do I Get Started?',
    meta: {
      robots: 'index, follow'
    }
  },

  desktoponly: {
    title: 'Desktop Only | Pouch',
    description: 'Here Are a Few of Our Most Frequently Asked Questions. What Is Pouch? How Does Pouch Work? Is Pouch Free? How Does Pouch Make Money? How Do I Get Started?'
  },

  unsupportedbrowser: {
    title: 'Unsupported Browser | Pouch',
    description: 'Here Are a Few of Our Most Frequently Asked Questions. What Is Pouch? How Does Pouch Work? Is Pouch Free? How Does Pouch Make Money? How Do I Get Started?'
  },

  blog: {
    title: 'Pouch Blog | Pouch',
    description: 'Pouch Blog. All the Best Money-Saving Hints and Tips from the Pouch Team.',
    meta: {
      robots: 'index, follow'
    }
  },

  post: {
    title: 'Post | Pouch Blog | Pouch',
    description: 'Pouch Blog. All the Best Money-Saving Hints and Tips from the Pouch Team.',
    meta: {
      robots: 'index, follow'
    }
  },

  amazondealfinder: {
    title: 'Amazon Deal Finder: Discover Secret 80%+ Discounts at Amazon | Pouch',
    description: 'Find the best Amazon deals with this easy tool. Huge discounts in on electronics, fashion, toys, beauty and more.',
    meta: {
      image: 'https://d32v8yls2yz871.cloudfront.net/images/seo/amazon-deal-finder-thumbnail.png',
      robots: 'index, follow'
    }
  },

  404: {
    title: 'Not Found | Pouch',
    meta: {
      'prerender-status-code': '404'
    }
  }
}
