<template>
  <ul :class="{ 'list': true, 'is-medium': medium }">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {
    medium: Boolean
  }
}
</script>

<style lang="sass" scoped>
.list
  box-shadow: 0px 2px 4px rgba(54, 54, 54, 0.08)
</style>
