import './bootstrap/registerGlobalHelpers'
import './bootstrap/configureServices'
import './bootstrap/registerControllerRouter'
import './bootstrap/registerGlobalComponents'
import './bootstrap/registerServiceWorker'
import './bootstrap/registerVuePlugins'
import './bootstrap/listenPouchExtensionMessages'

import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import store from './store'
import router from './router'
import Logger from '@/core/services/Logger'

import './listeners'
import './directives'

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Logger.log('[Boot] user-agent', Vue.prototype.$browser)
Logger.log('[Boot] configuration', Vue.prototype.$config)
