<template>
  <main-layout>
    <header class="section merchant-header">
      <div class="container">
        <slot name="header"></slot>
      </div>
    </header>

    <section class="section">
      <div class="container">
        <div class="columns is-mobile is-multiline">
          <main class="column is-9-desktop is-12-touch">
            <slot name="main"></slot>
          </main>

          <aside class="column is-3-desktop is-12-touch">
            <slot name="aside"></slot>
          </aside>
        </div>
      </div>
    </section>

    <slot></slot>
  </main-layout>
</template>

<script>
export default {

}
</script>
