
export default {

  /**
   * Generates a new unique user ID or retrieves it from storage.
   *
   * @return {string} The user id
   */
  getOrNew () {
    return localStorage.uid
      ? localStorage.uid
      : localStorage.uid = this.retrieveUserId() || this.generateUserId()
  },

  /**
   * Retrieves a unique user id from the URL.
   *
   * @return {string} The retrieved id
   */
  retrieveUserId () {
    return new URL(location.href.replace(/&amp;/g, '&')).searchParams.get('chid')
  },

  /**
   * Generates a unique user id.
   *
   * @return {string} The generated id
   */
  generateUserId () {
    return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11)
      .replace(/[018]/g, part => (Number(part) ^ Math.random() * 16 >> Number(part) / 4).toString(16))
  },

  /**
   * Modify a url to accept current uid.
   *
   * @param {URL} url
   * @return {URL}
   */
  addQuery (url) {
    if (localStorage.uid) {
      url.searchParams.set('chid', localStorage.uid)
    }

    return url
  }

}
