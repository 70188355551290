
export default async ({ to, from }) => {
  setTimeout(() => {
    try {
      scrollBy(0, document.querySelector(to.hash).getBoundingClientRect().top)
    } catch {
      scrollTo(0, 0)
    }
  }, 300)
}
