
export default {
  label: 'Post',
  name: 'post',
  folder: '/src/assets/posts',
  create: true,
  slug: '{{slug}}',
  fields: [
    {
      label: 'Date',
      name: 'date',
      widget: 'datetime',
      format: 'DD MMMM YY'
    },
    {
      label: 'Title',
      name: 'title',
      widget: 'string'
    },
    {
      label: 'Preview',
      name: 'preview',
      widget: 'string'
    },
    {
      label: 'Image',
      name: 'image',
      widget: 'image'
    },
    {
      label: 'Minutes',
      name: 'minutes',
      widget: 'number'
    },
    {
      label: 'Body',
      name: 'body',
      widget: 'markdown'
    }
  ]
}
