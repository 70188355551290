<template>
  <label :for="name" class="label">
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    name: String
  }
}
</script>
