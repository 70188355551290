import config from '@/config'

export default {

  namespaced: true,

  state: {
    giftCards: ''
  },

  mutations: {
    updateUserGiftCards (state, points) {
      state.giftCards = points
    }
  },

  actions: {
    /**
     * Fetch Points from Pouch.
     */
    async fetchGiftCards ({ commit }, payload) {
      const id = payload.userId
      return config.apiEndpoints.getGiftCards.getData({ id }, payload.parameters)
        .then(data => {
          commit('updateUserGiftCards', data)
        })
        .catch((err) => {
          throw err
        })
    }
  },

  getters: {
    accountGiftCards: state => state.giftCards
  }
}
