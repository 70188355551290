
import psl from 'psl'

/**
 * The Public Suffix List is a cross-vendor initiative to provide an accurate
 * list of domain name suffixes. The Public Suffix List is an initiative of the
 * Mozilla Project, but is maintained as a community resource. It is available
 * for use in any software, but was originally created to meet the needs of
 * browser manufacturers.
 *
 * @see http://publicsuffix.org
 * @see https://www.npmjs.com/package/psl
 */
export default class DomainData {
  /**
   * The domain data to be analysed.
   *
   * @param {string} data A domain data string
   * @example `store.boots.co.uk`
   */
  constructor (data) {
    this.data = psl.parse(data)
  }

  /**
   * Safely build an origin from domain data whether subdomain is included or
   * not - add `www` or provided subdomain if required.
   *
   * @param {string} subdomain The subdomain to be used if missing from `data`
   */
  deriveOrigin (subdomain = 'www') {
    return this.data.error ? null
      : this.data.subdomain
        ? `https://${this.data.input}`
        : `https://${subdomain}.${this.data.input}`
  }
}
