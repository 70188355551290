
export default {

  namespaced: true,

  state: {
    currentArticle: {}
  },

  mutations: {
    updateCurrentArticle (state, article) {
      state.currentArticle = article
    }
  },

  actions: {
    /**
     * Loads the active article.
     */
    async loadCurrentArticle ({ commit }, slug) {
      const article = require('@/containers/articles.json').find(article => article.slug === slug)

      return article ? commit('updateCurrentArticle', article) : Promise.reject()
    }
  }
}
