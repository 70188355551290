<template>
  <div class="loading has-background-primary"
       :style="{ width: `${barWidth}%`, opacity: barOpacity }"></div>
</template>

<script>
export default {
  data () {
    return {
      barOpacity: false,
      barWidth: 0,
      animationLength: 300
    }
  },

  created () {
    this.beingAnimation()
  },

  mounted () {
    this.$dispatcher.touch('router.beforeRouteLeave').addListener(() => {
      this.beingAnimation()
    })

    this.$dispatcher.touch('router.beforeRouteEnter').addListener(() => {
      this.finishAnimation()
    })
  },

  methods: {
    beingAnimation () {
      this.barOpacity = 1
      this.barWidth = 30
    },

    finishAnimation () {
      this.barWidth = 100

      setTimeout(() => {
        this.barOpacity = 0
      }, this.animationLength)

      setTimeout(() => {
        this.barWidth = 0
      }, this.animationLength * 2)
    }
  }
}
</script>

<style lang="sass" scoped>

.loading
  height: 2px
  left: 0
  opacity: 0
  position: fixed
  top: 0
  transition: .3s all linear
  z-index: 1000

</style>
