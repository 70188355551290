<template>
  <div :class="['modal', active ? 'is-active' : '']">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="box">
        <div class="delete" @click="$emit('close')"></div>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    active: Boolean
  },

  mounted () {
    addEventListener('keyup', this.closeModal)
  },

  beforeDestroy () {
    removeEventListener('keyup', this.closeModal)
  },

  methods: {
    closeModal (e) {
      (e.which || e.keyCode) === 27 && this.$emit('close')
    }
  }
}
</script>
