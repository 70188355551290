import FuzzySet from 'fuzzyset.js'

export default {
  /**
   * Finds the best matching entries in the supplied list.
   *
   * @param {string[]} list
   * @param {string} query
   */
  getBestMatches (list, query) {
    const literalMatches = list.filter(i => i.match(query))
    const fuzzyMatches = (FuzzySet(list).get(query) || []).map(i => i[1])

    return [...new Set(literalMatches.concat(fuzzyMatches))].slice(0, 5)
  }
}
