import config from '@/config'

export default {

  /**
   * Retrieve and validate browser locale setting.
   */
  browserLocale () {
    const locale = (window.navigator.language || '').toLowerCase().split('-')

    // Country and translation are valid or fallback to defaults
    const country = config.localization.availableCountries
      .find(c => c === locale[1]) || config.localization.defaultCountry

    const translation = config.localization.availableTranslations
      .find(t => t === locale[0]) || config.localization.defaultTranslation

    return `${translation}-${country}`
  },

  /**
   * Retrieve storage locale settings or fallback to browser.
   */
  get locale () {
    return localStorage.getItem('locale') || this.browserLocale()
  },

  /**
   * Retrieve country aspect of current locale.
   */
  get country () {
    return this.locale.toLowerCase().split('-')[1]
  },

  /**
   * Retrieve translation aspect of current locale.
   */
  get translation () {
    return this.locale.toLowerCase().split('-')[0]
  },

  /*
  * Retrieve path locale settings or fallback to undefined.
  */
  localeFromPath () {
    return /\/(\w{2}-\w{2})\/.*|$/.exec(window.location.pathname)[1]
  },

  /**
   * Format pattern matching base locale path.
   *
   * https://github.com/pillarjs/path-to-regexp#custom-matching-parameters
   */
  localizedRoot () {
    const countries = config.localization.availableCountries.join('|')
    const translations = config.localization.availableTranslations.join('|')

    return `/:translation(${translations})-:country(${countries})`
  },

  /**
   * Strip injected path of any base param matching locale data.
   *
   * @param {string} path the path to strip
   */
  stripLocale (path) {
    const match = /\/(en|de)-(gb|de)(\/.*|$)/.exec(path)

    return match === null ? path : match[3]
  },

  /**
   * Transforms country data for use between services.
   *
   * @param {string} country the country to transform
   */
  transformCountry (country) {
    // At present we only need to handle 'gb' -> 'uk' for API calls
    const map = { gb: 'uk', de: 'de' }

    return map[country]
  }

}
