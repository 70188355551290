import config from '@/config'
import cache from '@/core/services/Cache'

/**
 * This whole functionality should be moved to the backend for v2.
 */
export default class Scraper {
  /**
   * Class constructor.
   *
   * @param {string} url
   */
  constructor (url) {
    this.url = url
  }

  /**
   * Scrape basic info from meta tags.
   *
   * @return {Promise<any>}
   */
  getBasicInfo () {
    return cache(`scraped_${this.url}`, () => {
      return config.scraping.endpoint.get({ url: this.url })
        .then(({ data }) => {
          const description = this._match(data, /(?:meta[^>]+name="description"[^>]+content="([^"]+)")/i)

          const facebook = this._match(data, /href=(?:"|')(https?:\/\/(www\.)?facebook\.com\/[\w._-]+).*(?:"|')/i)
          const twitter = this._match(data, /href=(?:"|')(https?:\/\/(www\.)?twitter\.com\/(#!\/)?[\w._-]+).*(?:"|')/i)
          const instagram = this._match(data, /href=(?:"|')(https?:\/\/(www\.)?instagram\.com\/[\w._-]+).*(?:"|')/i)

          return {
            description,
            social: [
              { media: 'facebook', link: facebook, name: 'Facebook', action: 'Like' },
              { media: 'twitter', link: twitter, name: 'Twitter', action: 'Follow' },
              { media: 'instagram', link: instagram, name: 'Instagram', action: 'Follow' }
            ]
          }
        })
    }, 86400)
  }

  /**
   * Matches the provided regex against the haystack.
   *
   * @private
   * @param {string} haystack
   * @param {RegExp} regex
   */
  _match (haystack, regex) {
    const match = haystack.match(regex)

    return match && match[1] ? match[1].trim() : undefined
  }
}
