import config from '@/config'

export default {

  namespaced: true,

  state: {
    topRetailers: []
  },

  mutations: {
    async updateRetailersFromApi (state, data) {
      state.topRetailers = data
    }
  },

  actions: {
    /**
     * Loads top retailers from the APIs.
     */
    async loadTopRetailers ({ commit }) {
      const timestamp = new Date().getTime()

      const featuredVouchers = await config.apiEndpoints.gsgFeaturedVouchers.getData()
        .then(data => data.filter(x => Boolean(x.coupon_code)))

      await Promise.all(featuredVouchers.map((x) => {
        const id = x.id_merchant
        return config.apiEndpoints.gsgMerchantDetail.getData({ id })
          .then((data) => {
            return {
              name: data.merchant.name,
              url: data.merchant.url,
              logo: data.merchant.logo,
              codes: data.vouchers.filter(x => x.end_date >= timestamp && x.coupon_code).length
            }
          })
      })).then(data => commit('updateRetailersFromApi', data))
    }
  }
}
