import Vue from 'vue'
import Utm from './Utm'
import config from '@/config'
import Locator from './Locator'

export default {

  /**
   * Merges template data with a list data
   *
   * @param template default set of fields
   * @param data case-specific set of fields
   */
  mergeData (template, data) {
    // eslint-disable-next-line camelcase
    const merge_fields = {
      ...template.body.merge_fields,
      ...data.body.merge_fields || {}
    }

    return {
      body: {
        ...template.body,
        ...data.body,
        merge_fields
      },
      key: data.key
    }
  },

  /**
   * Creates the Data object for Mailchimp by Locale
   *
   * @param email user email
   * @param listId reference to configuration (subscription)
   */
  getTemplate (email, listId) {
    const utm = Utm.get()
    const browserName = Vue.prototype.$browser.name
    const countryCode = Locator.country

    const template = {
      body: {
        email_address: email,
        status: '',
        merge_fields: {
          FNAME: '',
          LNAME: '',
          FORMSOURCE: '',
          SOURCE: utm.utm_source,
          MEDIUM: utm.utm_medium,
          CAMPAIGN: utm.utm_campaign,
          BROWSER: browserName,
          SIGNEDUP: '',
          PROVIDER: ''
        }
      }
    }

    return this.mergeData(template, config.subscriptions[countryCode][listId])
  }

}
