import Controller from './Controller'

export default class AuthController extends Controller {
  /**
   * Doc.
   */
  oauth ({ to }) {
    if (!window.opener) {
      return '/login'
    }

    return window.opener.postMessage({
      response: location.href
    }, '*')
  }

  delete () {
    this.$store.dispatch('account/logout')
    return '/uninstall'
  }
}
