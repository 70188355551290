<template>
  <div>
    <cookie-notice-modal :active="showModal" @close="showModal = false" @accepted="showModal = false; noticeAvoided = false; dismissNotice()" />
    <div class="cookie-notice" v-show="noticeAvoided">
      <div class="container cookie-notice-container">
        <div class="cookie-notice-icon is-hidden-mobile">
          <span class="icon is-medium">
            <i class="fas fa-cookie-bite fa-lg"></i>
          </span>
        </div>
        <div class="cookie-notice-text">
          <i18n path="partials.cookie-notice.label" tag="div">
            <template #cookie-policy>
              <router-link
                :to="{ name: 'cookiepolicy', params: routeParams() }"
                v-t="`links.cookie-policy`"
              ></router-link>
            </template>
            <template #privacy-policy>
              <router-link
                :to="{ name: 'privacypolicy', params: routeParams() }"
                v-t="`links.privacy-policy`"
              ></router-link>
            </template>
          </i18n>
        </div>
        <div class="cookie-notice-dismiss">
          <div>
            <p-button secondary small
              @click="acceptAll(); dismissNotice()"
              v-t="`partials.cookie-notice.dismiss-button.accept-close`"
            ></p-button>
          </div>
          <div>
            <p-button small
              @click="showModal = true;"
              v-t="`partials.cookie-notice.dismiss-button.cookie-settings`"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Locator from '@/core/services/Locator'
import CookieNoticeModal from './CookieNoticeModal'

export default {
  components: { CookieNoticeModal },

  data () {
    return {
      showModal: false,
      noticeAvoided: false
    }
  },

  mounted () {
    this.checkNoticeAccepted()
  },

  methods: {
    checkNoticeAccepted () {
      localStorage.getItem('cookieNoticeShown') ? this.noticeAvoided = (localStorage.cookieNoticeShown === 'false') : this.noticeAvoided = true
    },

    acceptAll () {
      cookieCategorizer.setCategory('targeting')
      this.noticeAvoided = false
    },

    dismissNotice () {
      localStorage.cookieNoticeShown = !(this.noticeShown = false)
    },

    routeParams () {
      return { translation: Locator.translation, country: Locator.country }
    }
  }
}
</script>
