var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({class:{
          'button': true,

          'is-default': _vm.ordinary,
          'is-primary': _vm.primary,
          'is-secondary': _vm.secondary,
          'is-tertiary': _vm.tertiary,
          'is-white': _vm.white,
          'is-facebook': _vm.facebook,
          'is-messenger': _vm.messenger,
          'is-text': _vm.text,
          'is-danger': _vm.danger,

          'is-loading': _vm.loading,

          'is-small': _vm.small,
          'is-medium': _vm.medium,
          'is-large': _vm.large,
          'is-fullwidth': _vm.fullwidth,

          'has-text-weight-semibold': _vm.bold
        },attrs:{"disabled":_vm.disabled}},_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }