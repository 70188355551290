<template>
  <div
    @click.prevent="toggle"
    class="dropdown input"
    :class="{'dropdown--active': sharedState.active}"
  >
    <slot name="toggler">
      <span
        class="dropdown__label"
        :class="{ 'dropdown__label--faded': !selected }"
      >
        <span v-if="selected"
          ><b>{{ label }}</b
          >: {{ selected }}</span
        >
        <span v-else>{{ label }}</span>
      </span>
      <span class="dropdown__arrow"><i :class="`fas dropdown__arrow--faded ${active ? 'fa-chevron-up' : 'fa-chevron-down'}`"></i></span>
    </slot>
    <div v-if="active" class="">
      <ul class="dropdown__list">
        <li
          class="dropdown__list-item"
          :class="{ 'dropdown__list-item--active': item === selected }"
          v-for="(item, key) in items"
          :key="key"
          @click="handleItemClick(item)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    label: String,
    items: Array,
    initial: {
      type: String,
      default: ''
    }
  },
  computed: {
    active () {
      return this.sharedState.active
    },
    selected () {
      return this.sharedState.selected
    }
  },
  data () {
    return {
      sharedState: {
        active: false,
        selected: this.initial
      }
    }
  },
  methods: {
    toggle () {
      this.sharedState.active = !this.sharedState.active
    },
    handleItemClick (item) {
      this.sharedState.selected = item
      this.$emit('selected', item)
    }
  }
}
</script>

<style lang="sass" scoped>
.dropdown
  position: relative
  font-size: 1rem
  width: 100%
  cursor: pointer
  height: 3.2rem
  margin: 0.4rem 0
  padding-left: calc(1.4rem - 1px)
  border: 1px solid #D8D8D8
  border-radius: 4px
  box-shadow: none
  background-color: white
  color: #575757
  &--active
    border-color: #FF6441

  &__label
    position: absolute
    color: #363636
    font-size: 1rem
    top: 13px
    left: 24px
    &--faded
      opacity: 0.5

  &__list
    position: absolute
    left: 0
    right: 0
    top: 52px
    background-color: white
    z-index: 999
    padding: calc(1.4rem - 1px)
    box-shadow: 0px 4px 8px rgba(54, 54, 54, 0.1)
    border-radius: 4px

  &__list-item
    cursor: pointer
    padding-bottom: 0.5rem
    &:last-of-type
      padding-bottom: 0
    &:hover, &--active
      color: #FF6441

  &__arrow
    position: absolute
    color: #575757
    font-size: 1rem
    top: 13px
    right: 18px

    &--faded
      opacity: 0.5
</style>
