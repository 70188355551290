<template>
    <span @click="linkClicked()">
        <slot></slot>
    </span>
</template>

<script>
import Uid from '@/core/services/Uid'
import Utm from '@/core/services/Utm'
import Cookie from '@/core/services/Cookie'

export default {
  props: {
    targetUrl: String
  },

  methods: {
    linkClicked () {
      const url = Utm.addItmQuery(this.targetUrl)
      const ch = Cookie.cookieBox().customerHash
      url.searchParams.set('chid', ch || Uid.getOrNew())

      // If we have the cookie setting, then add it as an ITM to the link
      const cookieCatSlug = window.localStorage.getItem('cookie:category')
      const cookieNoticeShown = window.localStorage.getItem('cookieNoticeShown')
      if (cookieCatSlug && cookieNoticeShown === 'true') {
        url.searchParams.set('cookieslug', cookieCatSlug)
      }

      window.location.href = url
    }
  }
}
</script>
