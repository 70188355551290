import Controller from './Controller'
import Locator from '@/core/services/Locator'
import influencers from '@/containers/influencers'

export default class PageController extends Controller {
  /**
   * Load all necessary info to be shown on the landing page.
   */
  async home ({ to, from }) {
    this.$store.dispatch('loadPublications')
    this.$store.dispatch('loadTestimonials')
    this.$store.dispatch('loadFeaturedRetailers')
  }

  /**
   * Load all necessary info as for `home` action plus custom retailer data.
   */
  async crlp ({ to, from }) {
    const retailer = to.params.retailer

    this.$store.dispatch('loadPublications')
    this.$store.dispatch('loadTestimonials')
    this.$store.dispatch('loadFeaturedRetailers')

    return this.$store.dispatch('crlp/loadCrlpData', retailer)
      .catch(() => {
        // Provide the preferred locale params for redirect
        const [translation, country] = Locator.locale.split('-')

        return ({ name: 'home', params: { translation, country } })
      })
  }

  /**
   * Load influencer name and testimonials for the 'cilp' page.
   */
  async cilp ({ to, from }) {
    const influencer = to.params.influencer
    const inf = influencers[influencer]

    this.$store.dispatch('loadTestimonials')
    this.$store.dispatch('loadInfluencer', {
      name: influencer,
      campaign: inf.utm_campaign,
      content: [
        inf.subtitle ?? false,
        inf.message ?? false
      ]
    })
  }

  /**
   * Load all faqs.
   */
  async faq ({ to, from }) {
    return this.$store.dispatch('loadFaq')
  }

  /**
   * Load all Terms and Conditions.
   */
  async tos ({ to, from }) {
    return this.$store.dispatch('loadTos')
  }

  /**
   * Load all press publications.
   */
  async press ({ to, from }) {
    return this.$store.dispatch('loadPublications')
  }

  /**
   * Load all featured retailers.
   */
  async benefits ({ to, from }) {
    return this.$store.dispatch('loadFeaturedRetailers')
  }

  /**
   * Load Page version for testing the best option.
   */
  async onboarding ({ to, from }) {
    return this.$store.dispatch('onboarding/loadTopRetailers')
  }
}
