<template>
  <p-modal :active="active" @close="emitEvent('close')">
    <div class="cookie-settings has-padding-4 content">
      <h3 class="title is-3" v-t="`partials.cookie-modal.title`"></h3>

      <p v-t="`partials.cookie-modal.description`"></p>

      <p>
        <strong v-t="`partials.cookie-modal.decision`"></strong>
      </p>

      <div class="cookie-settings-choices">
        <div
          class="choice"
          :class="{ 'is-active': selected.level >= category.level }"
          v-for="(category, key) in categories"
          :key="key"
          @click="chooseCategory(category)"
        >
          <div class="choice-icon"><i class="fas" :class="`fa-${category.icon}`"></i></div>
          <div class="choice-label">{{ category.name }}</div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <h5 class="title is-5" v-t="`partials.cookie-modal.pouch-will`"></h5>

          <ul class="is-size-7">
            <li v-for="(permission, key) in permissions.filter(p => p.min <= selected.level)" :key="key">
              {{ permission.text }}
            </li>
          </ul>
        </div>
        <div class="column" v-show="permissions.filter(p => p.min > selected.level).length">
          <h5 class="title is-5" v-t="`partials.cookie-modal.pouch-wont`"></h5>

          <ul class="is-size-7">
            <li v-for="(permission, key) in permissions.filter(p => p.min > selected.level)" :key="key">
              {{ permission.text }}
            </li>
          </ul>
        </div>
      </div>

      <p class="has-text-right">
        <p-button primary @click="saveCategory()" v-t="`partials.cookie-modal.accept-close`"></p-button>
      </p>
    </div>
  </p-modal>
</template>

<script>
export default {
  props: ['active'],

  data: () => ({
    categories: [
      {
        level: 0,
        icon: 'plug',
        name: 'Necessary',
        slug: 'necessary'
      },
      {
        level: 1,
        icon: 'sync-alt',
        name: 'Tracking',
        slug: 'tracking'
      },
      {
        level: 2,
        icon: 'sliders-h',
        name: 'Functionality',
        slug: 'functional'
      },
      {
        level: 3,
        icon: 'cog',
        name: 'Analytics & Performance',
        slug: 'analytical'
      },
      {
        level: 4,
        icon: 'bullseye',
        name: 'Targeting',
        slug: 'targeting'
      }
    ],

    permissions: [
      {
        min: 0,
        text: 'Remember your cookie settings. It will enable you to browse and use some of its basic features, such as access to secure areas.'
      },
      {
        min: 1,
        text: 'Track the use of promotional offers provided on it to allocate sales or other transactions to the respective offer on our website in order to receive our commission.'
      },
      {
        min: 2,
        text: 'Remember your preferences.'
      },
      {
        min: 3,
        text: 'Collect information that is used either in aggregate form to help us understand how this website is being used, to improve user experience and fix issues on the website quickly or to help us customize our websites and services for you in order to enhance your experience.'
      },
      {
        min: 4,
        text: 'Learn from your website visits and its content more relevant to your interests and to deliver you content tailored to your interests.'
      },
      {
        min: 4,
        text: 'Control the frequency with which promotional offers are displayed to you.'
      },
      {
        min: 4,
        text: 'Serve advertisements on other websites across the internet to previous visitors to our site and measure the effectiveness of such ads.'
      }
    ],

    selected: {}
  }),

  mounted () {
    this.selected = this.categories[0]
  },

  methods: {
    chooseCategory (category) {
      this.selected = category
    },

    emitEvent (value) {
      this.$emit(value)
    },

    saveCategory () {
      cookieCategorizer.setCategory(this.selected.slug)
      this.$emit('accepted')
    }
  }
}
</script>
