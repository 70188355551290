<template>
    <p :class="{
      'control': true,
      'has-icons-left': !!iconLeft,
      'has-icons-right': !!iconRight,
      'is-expanded': expanded
    }">
      <input
        :class="{ 'input': true, 'is-medium': medium, 'is-small': small }"
        :disabled="disabled"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :id="name"
        @input="$emit('input', $event.target.value)"
        @keyup="$emit('keyup', $event)"
      >
      <span class="icon is-left" v-if="!!iconLeft">
        <i :class="['fas', `fa-${iconLeft}`]"></i>
      </span>
      <span class="icon is-right" v-if="!!iconRight">
        <i :class="['fas', `fa-${iconRight}`]"></i>
      </span>
    </p>
</template>

<script>
export default {
  props: {
    value: String,
    name: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text'
    },
    iconLeft: String,
    iconRight: String,
    small: Boolean,
    medium: Boolean,
    disabled: Boolean,
    expanded: Boolean
  }
}
</script>
