<template>
  <div
    :class="'is-size-' + this.size + '-desktop' + ' is-size-' + this.size + '-touch has-text-warning'"
  >
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star-half-alt star-filled-on-4and7">
    <span></span><span></span>
    </i>
  </div>
</template>

<script>

export default {
  props: {
    size: {
      default: '3',
      type: String
    }
  }
}
</script>
