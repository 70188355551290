/* eslint-disable no-console */

import config from '@/config'

export default {
  /**
   * Log data on the 'log' level.
   *
   * @param {any[]} data
   */
  log (...data) {
    if (config.app.debug) {
      console.log(...data)
    }
  },

  /**
   * Log data on the 'warn' level.
   *
   * @param {any[]} data
   */
  warn (...data) {
    if (config.app.debug) {
      console.warn(...data)
    }
  },

  /**
   * Log data on the 'error' level.
   *
   * @param {any[]} data
   */
  error (...data) {
    if (config.app.debug) {
      console.error(...data)
    }
  }
}
