
/**
 * Insired by https://alligator.io/vuejs/vue-router-modify-head/
 * Seo tags applying helper.
 *
 * @param {any} seo localised SEO data passed by `ApplySeo` middleware
 */
export const applySeo = (seo) => {
  // Clear any old meta tags.
  Array
    .from(document.querySelectorAll('[data-vue-router-controlled]'))
    .map(el => el.parentNode.removeChild(el))

  // Modify the title.
  document.title = seo.title

  // Modify the meta tags.
  Object.keys(seo.meta).map((key) => {
    const tag = document.createElement('meta')

    tag.setAttribute('name', key)
    tag.setAttribute('property', key === 'fb:app_id' ? key : `og:${key}`)
    tag.setAttribute('content', seo.meta[key])

    // We use this to track only meta tags that we create.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  }).forEach(tag => document.head.appendChild(tag))
}
