<template>
  <div>
    <navbar></navbar>
    <slot></slot>
    <page-footer></page-footer>
  </div>
</template>

<script>
import Navbar from '@/views/partials/Navbar'
import PageFooter from '@/views/partials/PageFooter'

export default {
  components: { Navbar, PageFooter }
}
</script>
