import Locator from '@/core/services/Locator'

export default async ({ to, from }) => {
  const locale = (Locator.localeFromPath() || Locator.locale)

  if (to.query.c === undefined) {
    const url = new URL(window.location.href)

    url.searchParams.set('c', '')

    // Redirect once to refresh the cache.
    setTimeout(() => window.location.href = `/${locale}/out?url=${url.toString()}`, 3000)

    return new Promise(() => {})
  }
}
