
export default {
  label: 'Article',
  name: 'article',
  folder: '/src/assets/articles',
  create: true,
  slug: '{{path}}',
  fields: [
    {
      label: 'Date',
      name: 'date',
      widget: 'datetime',
      format: 'DD MMMM YY'
    },
    {
      label: 'Path',
      name: 'path',
      widget: 'string',
      required: true
    },
    {
      label: 'Title',
      name: 'title',
      widget: 'string'
    },
    {
      label: 'Subtitle',
      name: 'subtitle',
      widget: 'string',
      required: false
    },
    {
      label: 'Image',
      name: 'image',
      widget: 'image'
    },
    {
      label: 'Body',
      name: 'body',
      widget: 'markdown'
    }
  ]
}
