/**
 * How to use this script:
 *
 * This contains all <head> tag changes that are applied before entering
 * a route for SEO purposes. The configuration is mapped by the route name.
 * It contains changes to the <title> tag and various <meta> tags. You are
 * allowed to access the route object of the destination route.
 *
 * Note that if you create an seo interception for a route, both title and meta
 * fields are required.
 *
 * If no interception is defined for a route, nearest parent route is
 * considered. If there is no such route, the SEO script won't be executed.
 */
export const rules = {

  default: {
    title: 'Suche nie wieder nach einem Gutscheincode | Pouch',
    description: 'Pouch ist ein KOSTENLOSES Tool, dass jeden Gutscheincode im Internet mit einem Klick anwendet.',
    meta: {
      locale: 'de',
      robots: 'noindex, follow',
      type: 'website',
      site_name: 'Pouch',
      image: 'https://d32v8yls2yz871.cloudfront.net/images/seo/pouch.png',
      'twitter:card': 'summary_large_image',
      'twitter:site': '@JoinPouch',
      'twitter:creator': '@JoinPouch'
    }
  },

  home: {
    title: 'Suche nie wieder nach einem Gutscheincode | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  bot: {
    title: 'Sofort sparen mit unserem Facebook Messenger | Pouch',
    description: 'KI-basierter Deal-Assistent, der die besten Gutscheincodes in deinen Posteingang bringt.'
  },

  uninstall: {
    title: 'Schade, dass Du gehst | Pouch'
  },

  contact: {
    title: 'Kontaktiere uns | Pouch',
    description: 'Nimm Kontakt mit Pouch auf. Pouch ist ein kostenloses Shopping-Tool, dass jeden Gutscheincode im Internet mit einem Klick anwendet.'
  },

  privacypolicy: {
    title: 'Datenschutzerklärung | Pouch',
    description: 'Bei Pouch schätzen wir Ehrlichkeit und Transparenz bei allem, was wir tun. Deshalb wollen wir unsere Datenschutzrichtlinien so klar und einfach wie möglich erklären.',
    meta: {
      robots: 'index, follow'
    }
  },

  tandcs: {
    title: 'AGBs | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  cookiepolicy: {
    title: 'Cookie Richtlinien | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  retailerbenefits: {
    title: 'Vorteile für Händler | Pouch',
    description: 'Pouch ist die Nr. 1 in Deutschland, wenn es darum geht, Zeit und Geld beim Online-Shopping zu sparen. Wir arbeiten mit über 1000 Einzelhändlern zusammen, um Ihren idealen Kunden die besten Rabatte zu gewähren.',
    meta: {
      robots: 'index, follow'
    }
  },

  press: {
    title: 'Presse | Pouch',
    meta: {
      robots: 'index, follow'
    }
  },

  faq: {
    title: 'FAQ | Pouch',
    description: 'Hier sind ein paar unserer am häufigsten gestellten Fragen. Was ist Pouch? Wie funktioniert Pouch? Ist Pouch kostenlos? Wie verdient Pouch Geld? Wie fange ich an?',
    meta: {
      robots: 'index, follow'
    }
  },

  desktoponly: {
    title: 'Desktop Only | Pouch',
    description: 'Hier sind ein paar unserer am häufigsten gestellten Fragen. Was ist Pouch? Wie funktioniert Pouch? Ist Pouch kostenlos? Wie verdient Pouch Geld? Wie fange ich an?'
  },

  unsupportedbrowser: {
    title: 'Nicht unterstützter Browser | Pouch',
    description: 'Hier sind ein paar unserer am häufigsten gestellten Fragen. Was ist Pouch? Wie funktioniert Pouch? Ist Pouch kostenlos? Wie verdient Pouch Geld? Wie fange ich an?'
  },

  blog: {
    title: 'Pouch Blog | Pouch',
    description: 'Pouch Blog. Die besten Tipps und Tricks zum Geldsparen vom Pouch Team.',
    meta: {
      robots: 'index, follow'
    }
  },

  post: {
    title: 'Post | Pouch Blog | Pouch',
    description: 'Pouch Blog. Die besten Tipps und Tricks zum Geldsparen vom Pouch Team.',
    meta: {
      robots: 'index, follow'
    }
  },

  amazondealfinder: {
    title: 'Amazon Deal Finder: Entdecke geheime 80%+ Rabatte bei Amazon | Pouch',
    description: 'Finde die besten Amazon-Deals mit diesem einfachen Tool. Riesige Rabatte auf Elektronik, Mode, Spielzeug, Beauty und mehr.',
    meta: {
      image: 'https://d32v8yls2yz871.cloudfront.net/images/seo/amazon-deal-finder-thumbnail.png',
      robots: 'index, follow'
    }
  },

  404: {
    title: 'Not Found | Pouch',
    meta: {
      'prerender-status-code': '404'
    }
  }
}
