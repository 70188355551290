import Vue from 'vue'
import Utm from '@/core/services/Utm'
import config from '@/config'
import cache from '@/core/services/Cache'
import { env } from '@/bootstrap/registerGlobalHelpers'

export default {

  namespaced: true,

  state: {
    user: null,
    userData: null
  },

  mutations: {
    setUser (state, user) {
      state.user = user
    },

    setUserData (state, userData) {
      state.userData = userData
    },

    updateUserAttributes (state, attrs) {
      state.user.attributes = { ...state.user.attributes, ...attrs }
    }
  },

  actions: {
    /**
     * Log user in via Email/Oauth and store the profile.
     */
    async login ({ commit }, { provider, credentials }) {
      if (provider === 'email') {
        await Vue.prototype.$auth
          .signIn({
            username: credentials.email,
            password: credentials.password
          }).then(profile => {
            commit('setUser', profile)
          })
      } else {
        await Vue.prototype.$auth
          .federatedSignIn({
            provider: provider
          })
      }
    },

    /**
     * Get logged-in user profile and set on store if not set already.
     */
    async profile ({ commit, state }) {
      state.user || await Vue.prototype.$auth.currentAuthenticatedUser()
        .then(profile => commit('setUser', profile))
    },

    /**
     * Sign user up with email but do not authorise.
     * Custom attibutes are included with the signup flow...
     */
    async register (_context, { credentials }) {
      const utms = Utm.get()
      const extId = localStorage.getItem('pouchInstalled')
      const browser = Vue.prototype.$browser.name

      await Vue.prototype.$auth
        .signUp({
          username: credentials.email,
          password: credentials.password,
          attributes: {
            given_name: credentials.firstName,
            family_name: credentials.lastName,
            // profile hold the value for the term and condition value
            profile: credentials.profile,
            'custom:ext_id': extId || '',
            'custom:ext_browser': (extId && browser) || '',
            'custom:utm_campaign': utms.utm_campaign || '',
            'custom:utm_medium': utms.utm_medium || '',
            'custom:utm_source': utms.utm_source || ''
          }
        })
    },

    /**
     * Resend the verification code. Return a Promise<string>.
     * @param {string} credentials
     */
    async resendRegister (_context, { credentials }) {
      await Vue.prototype.$auth
        .resendSignUp(credentials.email)
    },

    /**
     * Dispatch verification code email on password forgot.
     */
    async verification (_context, { email }) {
      await Vue.prototype.$auth
        .forgotPassword(email)
    },

    /**
     * Confirm new email for user on password forgot.
     */
    async confirmation (_context, { email, code, password }) {
      const decodedEmail = atob(email)
      return await Vue.prototype.$auth.forgotPasswordSubmit(decodedEmail, code, password)
    },

    /**
     * Update user attributes
     */
    async updateUser ({ commit }, { user }) {
      try {
        // some service parameters are set as non mutable and should not be updated, so we need to remove them
        // only cognito knows why they're there at all
        delete user.attributes.identities
        delete user.attributes['custom:utm_medium']
        delete user.attributes['custom:utm_source']
        return await Vue.prototype.$auth.updateUserAttributes(user, user.attributes)
      } catch {
        throw error
      }
    },

    /**
     * Update user password
     */
    async updateUserPassword (_context, { user, oldPassword, password }) {
      try {
        return await Vue.prototype.$auth.changePassword(user, oldPassword, password)
      } catch {
        throw error
      }
    },

    /**
     * Get User Data
     */
    async getUserData ({ commit, getters }, payload) {
      const id = getters.profile.attributes['custom:pouch_id']
      const parameters = {
        headers: {
          accept: 'application/json',
          Authorization: getters.profile.signInUserSession.idToken.jwtToken
        }
      }

      return config.apiEndpoints.getUserData.getData({ id }, parameters)
        .then(data => {
          commit('setUserData', data)
        })
        .catch((err) => {
          throw err
        })
    },

    /**
     * Update User Data
     */
    async updateUserData ({ commit, getters }, payload) {
      const id = getters.profile.attributes['custom:pouch_id']
      const headers = {
        headers: {
          accept: 'application/json',
          Authorization: getters.profile.signInUserSession.idToken.jwtToken
        }
      }

      return config.apiEndpoints.getUserData.put({ id }, payload.parameters, headers)
        .then(response => {
          if (response.data === 'Success' && response.status === 200) {
            const cacheKey = env('POUCH_API_USER_DATA') + '/' + id
            // update cache after success
            const dataToCache = { data: payload.parameters }
            cache(cacheKey, () => dataToCache, { cacheTimeout: 86400, forceRewrite: true })
            commit('setUserData', payload.parameters)
          }
        })
        .catch((err) => {
          throw err
        })
    }
  },

  getters: {
    /**
     * Provide the current user profile data.
     */
    profile: state => state.user,
    profileData: state => state.userData
  }
}
