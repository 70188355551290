<template>
  <div class="account">
    <navbar></navbar>
    <slot></slot>
  </div>
</template>

<script>
import Navbar from '@/views/partials/Navbar'

export default {
  components: { Navbar }
}
</script>

<style lang="sass" scoped>
.account
  display: flex
  background-color: #FBF7F7
</style>
