import i18n from '@/i18n'

export default async ({ to, from }) => {
  // Form locale from translation and country params
  const { translation, country } = to.params
  const locale = `${translation}-${country}`

  // Update locale on i18n service and local storage
  i18n.locale = translation
  localStorage.setItem('locale', locale)
}
