<template>
  <span>
    <input type="checkbox" @click="toggle()">
  </span>
</template>

<script>
export default {
  props: ['direct'],

  data () {
    return {
      active: false
    }
  },

  watch: {
    direct (bool) {
      this.active = !!bool
    }
  },

  methods: {
    toggle () {
      this.$emit('change', this.active = !this.active)
    }
  }
}
</script>
