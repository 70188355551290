import Logger from '@/core/services/Logger'

const swUrl = `${process.env.BASE_URL}service-worker.js`

Logger.log('[SW] registering service worker at', swUrl)

// The service worker funcionality needs to be present in the current browser
// and we only register in on staging/production.
if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
  navigator.serviceWorker.register(swUrl, {
    scope: '/',
    skipWaiting: true,
    clientsClaim: true
  }).then(_ => Logger.log('[SW] service worker registration succeeded.'))
    .catch(e => Logger.warn('[SW] service worker registration failed.', e))
} else {
  Logger.log('[SW] service workers are not supported in the current environment.')
}
