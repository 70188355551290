<template>
  <div>
    <nav class="navbar is-fixed-top" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand">
          <router-link class="navbar-item" to="/">
            <img v-lazy="`$/images/logo.png`" alt="Pouch" class="navbar-logo">
          </router-link>
        </div>
      </div>
    </nav>

    <slot></slot>
  </div>
</template>

<script>
export default {
  //
}
</script>
