
export default async ({ to, from }) => {
  return new Promise((resolve) => {
    let iterations = 0

    const interval = setInterval(() => {
      if ((window.heap && window.heap.length) || ++iterations > 20) {
        resolve()

        clearInterval(interval)
      }
    }, 100)
  })
}
