<template>
  <form @submit.prevent="onSubmit">
    <slot name="fields" :errors="errors" :data="data"></slot>
    <slot name="actions" :stage="stage" :stages="stages"></slot>
  </form>
</template>

<script>
import FormStage from '@/core/enums/FormStage'
import Validator from '@/core/validators/Validator'

export default {
  props: {
    validator: {
      type: Validator
    },
    transform: {
      type: Function,
      default: async d => d
    },
    handler: {
      type: Function,
      required: true
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    errors: {},
    stage: FormStage.LOADED,
    stages: FormStage,
    isValid: true
  }),

  methods: {
    onSubmit () {
      this.stage = FormStage.SUBMITTED
      this.errors = {}
      this.isValid = true

      this.transform(this.data)
        .then((res) => {
          this.errors = this.validator.apply(this.data, 'InvalidParameters')
          for (const [, value] of Object.entries(this.errors)) {
            if (value.length) this.isValid = false
          }
          if (this.isValid) this.handler(res)
        })
        .then(() => {
          if (this.isValid) {
            this.stage = FormStage.SUCCEEDED
            this.data = {}
            // keep the success message on for 3 secs
            setTimeout(() => {
              this.stage = FormStage.LOADED
            }, 3000)
            this.$emit('success')
          } else {
            this.stage = FormStage.LOADED
          }
        })
        .catch((error) => {
          this.stage = FormStage.ERRORED

          if (this.validator) {
            this.errors = this.validator.apply(this.data, error.code)
          }

          this.$emit('error', error)
        })
    }
  }
}
</script>
