import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import config from '@/config'

class GTM {
  constructor () {
    this.gtm = undefined
    this.isInitialized = false
  }

  init () {
    this.gtm = Analytics({
      app: 'pouch-website',
      plugins: [
        googleTagManager({
          containerId: config.analytics.tokens.gtm
        })
      ]
    })

    this.gtm.ready(() => this.isInitialized = true)
  }

  pageView (options) {
    this.gtm.page(options)
  }

  event (eventName) {
    this.gtm.track(eventName)
  }
}

const instance = new GTM()
export default instance
