<template>
  <div ref="wrapper" class="fixed" :style="{ minHeight: `${wrapperDimensions.height}px` }">
    <div ref="content" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navbarHeight: {
      type: Number,
      default: 97
    },
    offsetTop: {
      type: Number,
      default: 60
    },
    offsetBottom: {
      type: Number,
      default: 450
    }
  },

  data () {
    return {
      contentStyle: {
        position: '',
        top: null,
        bottom: null,
        width: null
      },
      wrapperDimensions: {
        height: '',
        width: ''
      }
    }
  },

  mounted () {
    this.determineWrapperDimensions()
    this.addScrollListener()
  },

  beforeDestroy () {
    this.removeScrollListener()
  },

  methods: {
    determineWrapperDimensions () {
      this.wrapperDimensions = {
        height: this.getContentRect().height,
        width: this.getContentRect().width
      }
    },

    handleScroll () {
      const collidesTop = this.getWrapperRect().top > (this.navbarHeight + this.offsetTop)
      const collidesBottom = this.getWrapperRect().bottom < (this.wrapperDimensions.height + this.offsetBottom)

      this.contentStyle = {
        position: collidesTop ? '' : collidesBottom ? 'absolute' : 'fixed',
        top: collidesBottom ? null : `${(this.navbarHeight + this.offsetTop)}px`,
        bottom: collidesBottom ? 0 : null,
        width: `${this.wrapperDimensions.width}px`
      }
    },

    addScrollListener () {
      addEventListener('scroll', this.handleScroll)
    },

    removeScrollListener () {
      removeEventListener('scroll', this.handleScroll)
    },

    getWrapperRect () {
      return this.$refs.wrapper.getBoundingClientRect()
    },

    getContentRect () {
      return this.$refs.content.getBoundingClientRect()
    }
  }
}
</script>
