
export default class ListenerBag {
  /**
   * Class constructor.
   */
  constructor () {
    this.listeners = []
  }

  /**
   * Adds a listener to this instance.
   *
   * @param {Listener} listener
   */
  addListener (listener) {
    this.listeners.push(listener)
  }

  /**
   * Dispatch all listeners on this instance with provided payload.
   *
   * @param {any} payload
   */
  dispatch (payload) {
    this.listeners.forEach(listener => listener(payload))
  }
}
