import ListenerBag from './ListenerBag'
import Logger from '@/core/services/Logger'

export default class Dispatcher {
  /**
   * Return the listener bag assigned to the specified event.
   *
   * @param {string} event
   * @return {ListenerBag}
   */
  static touch (event) {
    if (this.events === undefined) {
      this.events = {}
    }

    return this.events[event] === undefined
      ? this.events[event] = new ListenerBag()
      : this.events[event]
  }

  /**
   * Fires the event.
   *
   * @param {string} event
   * @param {any} payload
   */
  static fire (event, payload = null) {
    if (this.events === undefined || this.events[event] === undefined) {
      return
    }

    this.events[event].dispatch(payload)

    Logger.log(`[Dispatcher] ${event}`, payload)
  }
}
