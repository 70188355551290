import Vue from 'vue'
import Locator from '@/core/services/Locator'

/**
 * JS Responsive helpers.
 */

Vue.directive('hidden-extension-fit', {
  inserted: (el) => {
    if (
      crossDeviceSupport.supportedBrowser() &&
      !crossDeviceSupport.mobileOs()
    ) {
      el.style.display = 'none'
    }
  }
})

Vue.directive('only-extension-fit', {
  inserted: (el) => {
    if (
      !crossDeviceSupport.supportedBrowser() ||
      crossDeviceSupport.mobileOs()
    ) {
      el.style.display = 'none'
    }
  }
})

Vue.directive('only-locales', {
  inserted: (el, { value }) => {
    // Get the curent locale and match
    // against full value: `en-gb` etc.
    const locale = Locator.locale

    if (!value.includes(locale)) {
      el.style.display = 'none'
    }
  }
})

Vue.directive('hidden-with-extension', {
  inserted: (el) => {
    // const hideIfExtension = () => localStorage.pouchInstalled ? el.style.display = 'none' : null
    const hideIfExtension = () => document.querySelector('meta[name="hotukdeals-browser-extension"]') ? el.style.display = 'none' : null
    hideIfExtension()
    // keep checking cause extension could send the message later
    setInterval(hideIfExtension, 2000)
  }
})

Vue.directive('hidden-with-onboarding', {
  inserted: (el) => {
    // Checks if pathname
    // contains Onboarding.
    if (location.pathname.includes('onboarding')) {
      el.style.display = 'none'
    }
  }
})

Vue.directive('only-with-extension', {
  inserted: (el) => {
    if (!localStorage.pouchInstalled) {
      el.style.display = 'none'
    }
  }
})

Vue.directive('only-mobile', {
  inserted: (el) => {
    if (!crossDeviceSupport.mobileOs()) {
      el.style.display = 'none'
    }
  }
})

Vue.directive('only-desktop', {
  inserted: (el) => {
    if (crossDeviceSupport.mobileOs()) {
      el.style.display = 'none'
    }
  }
})

/**
 * The image lazy-loading directive.
 */

Vue.directive('lazy', (el, { value }) => {
  // If we find a CDN shorthand, replace it with the full URL.
  if (value.match(/^\$\//)) {
    value = value.replace(/^\$\//, Vue.prototype.$config.assets.distributionUrl)
  }

  const node = document.createElement(el.nodeName)
  node.onload = () => el.src = value
  node.src = value
})

Vue.directive('src', (el, { value }) => {
  // If we find a CDN shorthand, replace it with the full URL.
  if (value.match(/^\$\//)) {
    value = value.replace(/^\$\//, Vue.prototype.$config.assets.distributionUrl)
  }

  el.src = value
})

/**
 * The anchor scrolling directive.
 */

Vue.directive('scroll', (el, { value }) => {
  el.onclick = () => scrollBy(0, document.getElementById(value).getBoundingClientRect().top - 106)
})

/**
 * The dismissible element directive.
 */

Vue.directive('dismissible', {
  inserted: (el, { value }, node) => {
    if (localStorage[`banner-${value}-hidden`]) {
      return el.style.display = 'none'
    }

    const close = document.createElement('span')
    close.classList.add('delete')
    close.addEventListener('click', () => {
      localStorage[`banner-${value}-hidden`] = 1
      el.style.display = 'none'
    })

    el.appendChild(close)
  }
})
