<template>
    <p class="control">
      <textarea
        class="textarea"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        :id="name"
        @input="$emit('input', $event.target.value)"
      ></textarea>
    </p>
</template>

<script>
export default {
  props: {
    value: String,
    name: String,
    placeholder: String,
    disabled: Boolean
  }
}
</script>
