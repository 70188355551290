import axios from 'axios'
import Locator from './Locator'
import cache from '@/core/services/Cache'

export default class ApiUrl {
  /**
   * Class constructor.
   *
   * @param {string} url
   * @param {any} options
   */
  constructor (url, options = {}) {
    this.url = url
    this.options = options
  }

  /**
   * Invoke a get method on the url.
   *
   * @param {any} variables
   * @param {any} options
   * @return {Promise<any>}
   */
  async get (variables = {}, options = {}) {
    // Automatically inject a country value if not provided
    const country = Locator.country
    variables.locale = variables.locale || Locator.transformCountry(country)

    const url = Object.keys(variables).reduce((carry, key) => {
      return carry.replace(`{${key}}`, variables[key])
    }, this.url)

    return this.options.localCache === false
      ? axios.get(url, options)
      : cache(url, () => axios.get(url, options), this.options)
  }

  /**
   * Invoke a get method on the url and returns the data object.
   *
   * @param {any} variables
   * @param {any} options
   * @return {Promise<any>}
   */
  async getData (variables = {}, options = {}) {
    return this.get(variables, options).then(({ data }) => data)
  }

  /**
   * Invoke a post method on the url.
   *
   * @param {any} variables
   * @param {any} body
   * @param {any} options
   * @return {Promise<any>}
   */
  async post (variables = {}, body = {}, options = {}) {
    return axios.post(this.url, body, options)
  }

  /**
   * Invoke a put method on the url.
   *
   * @param {any} variables
   * @param {any} body
   * @param {any} options
   * @return {Promise<any>}
   */
  async put (variables = {}, body = {}, options = {}) {
    const url = Object.keys(variables).reduce((carry, key) => {
      return carry.replace(`{${key}}`, variables[key])
    }, this.url)
    return axios.put(url, body, options)
  }
}
