import md5 from 'md5'

/**
 * Caches the callback return value.
 *
 * @private
 * @param {string} key
 * @param {() => any} callback
 * @return {any}
 */
export default async (key, callback, options = {}) => {
  const cacheKey = `cache__${md5(key)}`
  const cache = JSON.parse(localStorage[cacheKey] || null)
  if (cache && cache.timeout > Date.now() && !options.forceRewrite) {
    return cache.data
  }

  const data = await callback()

  localStorage[cacheKey] = JSON.stringify({
    data: data,
    timeout: Date.now() + ((options.cacheTimeout || 600) * 1000)
  })

  return data
}
