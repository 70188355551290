import Dispatcher from '@/core/events/Dispatcher'

export default async ({ to, from }) => {
  /**
   * Fire an event if the application seems to be offline.
   */
  if (!navigator.onLine) {
    Dispatcher.fire('app.goneOffline')
  }
}
