import config from '@/config'

export default {

  namespaced: true,

  state: {
    points: {},
    trails: []
  },

  mutations: {
    updateUserPoints (state, points) {
      state.points = points
    },

    updateUserTrails (state, trails) {
      state.trails = trails
    }
  },

  actions: {
    /**
     * Fetch Points from Pouch API.
     */
    async fetchPoints ({ commit }, payload) {
      return config.apiEndpoints.getPoints.getData({}, payload)
        .then(data => {
          commit('updateUserPoints', data)
        })
        .catch((err) => {
          throw err
        })
    },

    /**
     * Fetch Trails from Pouch API.
     */
    async fetchTrails ({ commit }, payload) {
      return config.apiEndpoints.getActivity.getData({}, payload)
        .then(data => {
          commit('updateUserTrails', data)
        })
        .catch((err) => {
          throw err
        })
    }
  },

  getters: {
    accountPoints: state => state.points,
    accountTrails: state => state.trails
  }
}
