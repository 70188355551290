import config from '@/config'
import GA from 'universal-ga'
import Uid from '@/core/services/Uid'
import Mixpanel from 'mixpanel-browser'
import MGTRX from '@/mgtrx'
import GTM from '@/GTM'
import Locator from '@/core/services/Locator'
import Cookie from '@/core/services/Cookie'

// Setting the document cookie for Megatron initialisation
const d = new Date()
d.setTime(d.getTime() + 395 * 24 * 60 * 60 * 1e3)
const currentUrl = new URL(location.href.replace(/&amp;/g, '&'))
const urlChid = currentUrl.searchParams.get('chid')
const ch = Cookie.cookieBox().customerHash
document.cookie = `customerHash=${urlChid || ch || Uid.getOrNew()}; expires=${d.toUTCString()}; path=/;` // if exists, only update expiration date

// Determine current country locale for selecting localised tokens
const country = (Locator.localeFromPath() ?? Locator.locale).split('-')[1]

/*
 * Check the url for a cookieslug itm and if it was available, set it in
 *   the localStorage and prevent showing the cookie bar to the user
 **/
const cookieslug = currentUrl.searchParams.get('cookieslug')
if (cookieslug && cookieCategorizer.getCategoryBySlug(cookieslug)) {
  cookieCategorizer.setCategory(cookieslug)
  localStorage.cookieNoticeShown = true
}

cookieCategorizer.addService('analytical',
  () => Mixpanel.init(config.analytics.tokens.mixpanel))

cookieCategorizer.addService('analytical',
  () => GA.initialize(config.analytics.tokens.ga))

cookieCategorizer.addService('necessary',
  () => MGTRX.init(
    config.analytics.mgtrx.collect[country],
    config.analytics.mgtrx.library,
    config.analytics.mgtrx.cid
  )
)

cookieCategorizer.addService('targeting',
  () => GTM.init())
