<template>
  <nav
    class="nav"
    aria-label="main navigation"
    v-bind:class="{ 'account-nav': $route.path.includes('/account') }"
  >
    <div class="container">
      <div class="nav-cluster is-hidden-touch">
        <div class="nav-menu">
          <router-link
            class="nav-item logo-item"
            :to="{ name: 'home' }"
            style="min-width: calc(217px - 3.5rem)"
          >
            <img v-lazy="`$/images/logo.svg`" alt="Pouch" />
          </router-link>

          <div
            class="nav-item has-text-grey"
            v-show="onHomePage"
            :style="{ 'font-size': '1.2rem', cursor: 'default' }"
          >
            <h2 v-t="`partials.navbar.label`"></h2>
          </div>
        </div>
        <div class="nav-spacer"></div>
        <div class="nav-menu">
          <!-- <div class="nav-item has-dropdown" v-show="!onHomePage">
            <div class="nav-link has-text-grey-light">
              <div class="search-glass" v-t="`partials.navbar.nav-links.search`">
                <i class="fas fa-fw fa-search"></i>
              </div>
            </div>
            <div class="nav-dropdown">
              <div>
                <search></search>
              </div>
            </div>
          </div> -->

          <!-- <router-link
            class="nav-item"
            :to="{ name: 'deals' }"
            v-show="!onHomePage"
            v-only-locales="['en-gb', 'de-de']"
            v-t="`partials.navbar.nav-links.deals`"
          ></router-link> -->

          <!-- <router-link
            class="nav-item"
            :to="{ name: 'merchants' }"
            v-show="!onHomePage"
            v-only-locales="['de-de']"
            v-t="`partials.navbar.nav-links.retailers`"
          ></router-link> -->

          <!-- <router-link class="nav-item has-dropdown" :to="{ name: 'merchants' }" v-show="!onHomePage">
            <div class="nav-link" v-t="`partials.navbar.nav-links.retailers`"></div>

            <div class="nav-dropdown">
              <router-link
                class="nav-item"
                v-for="(retailer, key) in topRetailers"
                :to="{ name: 'merchant', params: { merchant: retailer.slug } }"
                :key="key"
                exact
              >
                {{ retailer.name }}
              </router-link>
            </div>
          </router-link> -->

          <!-- Top deals -->
          <!--
          <utm-itm-link class="nav-item" :targetUrl="dealsPage">
            TOP DEALS
          </utm-itm-link>
          -->

          <!-- Pouch Points Landing -->
          <!--
          <router-link class="nav-item" :to="{ name: 'points' }">
            POUCH POINTS
          </router-link>
          -->

          <!--
          <div
            class="nav-item has-dropdown"
            v-show="!onHomePage"
            v-only-locales="['en-gb']"
          >
            <div
              class="nav-link has-text-grey"
              v-t="`partials.navbar.nav-links.tools.title`"
            ></div>

            <div class="nav-dropdown has-one-column">
              <router-link class="nav-item is-rich" exact :to="{ name: 'bot' }">
                <div class="left">
                  <i
                    class="fab fa-facebook-messenger fa-2x has-text-messenger"
                  ></i>
                </div>
                <div class="right">
                  <p
                    class="has-text-weight-semibold"
                    v-t="`features.messenger-bot`"
                  ></p>
                  <p
                    class="has-text-grey-light is-size-7"
                    v-t="
                      `partials.navbar.nav-links.tools.dropdown.messenger-description`
                    "
                  ></p>
                </div>
              </router-link>
              <router-link
                class="nav-item is-rich"
                exact
                :to="{ name: 'amazondealfinder' }"
              >
                <div class="left">
                  <img
                    v-lazy="`$/images/amazon-deal-finder.png`"
                    alt="Amazon Deal Finder"
                  />
                </div>
                <div class="right">
                  <p
                    class="has-text-weight-semibold"
                    v-t="`features.amazon-deal-finder`"
                  ></p>
                  <p
                    class="has-text-grey-light is-size-7"
                    v-t="
                      `partials.navbar.nav-links.tools.dropdown.amazon-finder-description`
                    "
                  ></p>
                </div>
              </router-link>
            </div>
          </div>

          <router-link
            class="nav-item"
            :to="{ name: 'blog' }"
            v-show="!onHomePage"
            v-only-locales="['en-gb']"
            v-t="`partials.navbar.nav-links.blog`"
          ></router-link>
          -->

          <!-- Profile Picture --->
          <utm-itm-link
            class="nav-item"
            :targetUrl="accountDiscoverBrandsUrl"
            v-show="!onHomePage && profile"
            v-only-locales="['en-gb']"
          >
            <img
              v-lazy="`$/images/account/placeholder.svg`"
              width="30"
              height="auto"
              class="placeholder"
              alt="Placeholder"
            />
            <span
              class="has-text-primary is-uppercase"
              v-t="`myaccount.title`"
            ></span>
          </utm-itm-link>

          <utm-itm-link
            class="nav-item"
            :targetUrl="accountLogoutUrl"
            v-show="!onHomePage && profile"
          >
            <span class="is-uppercase" v-t="`myaccount.menu.logout`"></span>
          </utm-itm-link>

          <!-- if profile exist do not show login and join-->
          <utm-itm-link
            class="nav-item"
            :targetUrl="accountLoginUrl"
            v-if="!profile"
          >
            LOGIN
          </utm-itm-link>
          <!--
          <utm-itm-link
            class="nav-item has-text-primary"
            :targetUrl="accountJoinUrl"
            v-if="!profile"
          >
            JOIN
          </utm-itm-link>
          -->

          <div class="nav-item" v-only-extension-fit v-hidden-with-extension>
            <add-to-browser
              v-t="`buttons.get-pouch`"
              position="Navbar"
            ></add-to-browser>
          </div>
        </div>
      </div>

      <div class="nav-cluster is-hidden-desktop">
        <div class="nav-item" v-only-locales="['en-gb']">
          <img v-lazy="`$/images/logo_small.png`" alt="Pouch" />
        </div>
        <div class="nav-spacer"></div>
        <div class="nav-menu">
          <router-link class="nav-item" :to="{ name: 'home' }">
            <img class="" v-lazy="`$/images/logo.svg`" alt="Pouch" />
          </router-link>
        </div>
        <div class="nav-spacer"></div>
        <div class="nav-menu" v-only-locales="['en-gb']">
          <div class="nav-item" @click="toggleMenu">
            <div class="nav-burger"></div>
          </div>
        </div>

        <div
          class="nav-slider"
          :class="{ 'is-active': menuShown }"
          v-only-locales="['en-gb']"
        >
          <div class="nav-slider-header">
            <div class="nav-spacer"></div>
            <div class="nav-menu">
              <div class="nav-item has-text-primary" @click="toggleMenu">
                <i class="fas fa-fw fa-times fa-lg"></i>
              </div>
            </div>
          </div>
          <!-- Mobile Menu - User not Logged && Logged && on homepagee -->
          <div class="nav-slider-content">
            <div class="nav-slider-body">
              <div
                class="nav-label"
                v-only-extension-fit
                v-hidden-with-extension
              >
                <add-to-browser
                  v-t="`buttons.get-pouch`"
                  position="Navbar"
                ></add-to-browser>
              </div>
              <utm-itm-link
                class="nav-label has-text-primary is-uppercase"
                :targetUrl="accountDiscoverBrandsUrl"
                v-show="!onHomePage && profile"
                v-only-locales="['en-gb']"
              >
                <img
                  v-lazy="`$/images/account/placeholder.svg`"
                  width="30"
                  height="auto"
                  class="placeholder"
                  alt="Placeholder"
                />
                <span
                  class="has-text-primary is-uppercase"
                  v-t="`myaccount.title`"
                ></span>
              </utm-itm-link>
              <!-- Join -->
              <utm-itm-link
                v-if="!profile"
                class="nav-label has-text-primary is-uppercase"
                :targetUrl="accountJoinUrl"
                v-t="`partials.navbar.nav-links.user.join`"
              >
              </utm-itm-link>
              <!-- Login -->
              <utm-itm-link
                v-if="!profile"
                class="nav-label has-text-primary is-uppercase"
                :targetUrl="accountLoginUrl"
                v-t="`partials.navbar.nav-links.user.login`"
              >
              </utm-itm-link>

              <!-- Top Deals -->
              <!--
              <utm-itm-link
                class="nav-label is-uppercase"
                :targetUrl="dealsPage"
              >
                TOP DEALS
              </utm-itm-link>
              -->
              <!-- Pouch Points Landing -->
              <!--
              <router-link
                class="nav-label is-uppercase"
                exact
                :to="{ name: 'points' }"
              >
                POUCH POINTS
              </router-link>
              -->
              <!-- Blog -->
              <!--
              <router-link
                class="nav-label is-uppercase"
                exact
                :to="{ name: 'blog' }"
                v-t="`partials.navbar.nav-links.other.blog`"
              ></router-link>
              -->
              <!-- Contact -->
              <router-link
                class="nav-label is-uppercase"
                exact
                :to="{ name: 'contact' }"
                v-t="`partials.navbar.nav-links.other.contact`"
              ></router-link>
              <!-- faq -->
              <router-link
                class="nav-label is-uppercase"
                target="_blank"
                exact
                :to="{ name: 'faq' }"
                v-t="`partials.navbar.nav-links.other.faq`"
              ></router-link>

              <utm-itm-link
                class="nav-label has-text-primary is-uppercase"
                :targetUrl="accountLogoutUrl"
                v-show="!onHomePage && profile"
              >
                <span class="is-uppercase" v-t="`myaccount.menu.logout`"></span>
              </utm-itm-link>
            </div>
          </div>
        </div>
        <div class="nav-cover"></div>

        <search mobile :active="searchShown" @close="toggleSearch"></search>
      </div>

      <div
        class="is-hidden-desktop has-text-centered has-text-grey"
        v-show="onHomePage"
        :style="{ 'font-size': '1rem', 'padding-bottom': '0.6rem' }"
      >
        <h2 v-t="`partials.navbar.label`"></h2>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import AddToBrowser from './AddToBrowser'
import Search from '@/views/partials/Search'
import config from '@/config'
import UtmItmLink from './UtmItmLink.vue'

export default {
  components: { AddToBrowser, Search, UtmItmLink },

  data: () => ({
    menuShown: false,
    searchShown: false,
    accountLoginUrl: config.accountLoginUrl,
    accountJoinUrl: config.accountJoinUrl,
    accountLogoutUrl: config.accountLogoutUrl,
    accountDiscoverBrandsUrl: config.accountDiscoverBrandsUrl,
    dealsPage: config.dealsPage,
    accountSiteBaseUrl: config.app.accountWebsiteUrl
  }),

  computed: {
    ...mapGetters({
      profile: 'account/profile',
      accountPoints: 'points/accountPoints'
    }),

    currentPage () {
      return this.$route.path
    },

    onHomePage () {
      return this.$route.fullPath === '/'
    }
  },

  methods: {
    toggleMenu () {
      this.menuShown = !this.menuShown
    },

    toggleSearch () {
      this.searchShown = !this.searchShown
    }
  },

  mounted () {
    this.$dispatcher.touch('router.beforeRouteLeave').addListener(() => {
      this.menuShown = false
    })
  }
}
</script>
<style lang="sass" scoped>
.profile
  display: flex
  margin: 1rem
  .profile-placeholder
    width: 60px
  .profile-info
    display: flex
    flex-direction: column
    justify-items: flex-start
    align-items: flex-start
    .profile-points
      display: flex
      justify-items: center
      align-items: center
    .pouch-currency
      width: 18px
      height: 18px
      margin-left: 6px
.nav-slider-body
  min-width: 227px
  overflow: none
.nav-label
  font-size: 20px
.mobile-plugin
  padding: 12px 0 12px 30px
  .plugin-name
    color: gray
  img
    margin-right: 8px
    &:hover
      color: #ff6441
.mobile-plugin.selected
  font-weight: 800
  background-color: #fbf7f7
.sub-mobile-plugin
  padding: 12px 0 12px 30px
  .plugin-name
    color: gray
    margin-left: 43px
.mobile-links
  display: flex
  flex-direction: column
  a
    color: gray
</style>
