import config from '@/config'
import Scraper from '@/core/services/Scraper'
import DomainData from '@/core/services/Domain'
import ListSearcher from '@/core/services/ListSearcher'

export default {

  namespaced: true,

  state: {
    searchResults: {
      merchants: []
    },
    currentMerchantData: {},
    scrapedMerchantData: {}
  },

  getters: {
    currentMerchantDeals: (state) => {
      const merchant = state.currentMerchantData.merchant || {}
      const deals = state.currentMerchantData.vouchers || []

      return deals.map(deal => ({ ...deal, merchant }))
    },

    currentMerchant: (state) => {
      const merchant = state.currentMerchantData.merchant || {}
      const metadata = state.scrapedMerchantData || {}
      const deals = state.currentMerchantData.vouchers || []

      return {
        ...merchant,
        ...metadata,
        codesCount: deals.filter(d => d.voucher_type === 'code').length,
        offersCount: deals.filter(d => d.voucher_type === 'deal').length
      }
    }
  },

  mutations: {
    updateSearchResults (state, data) {
      state.searchResults.merchants = data
    },

    updateCurrentMerchantData (state, data) {
      state.currentMerchantData = data
    },

    updateScrapedMerchantData (state, data) {
      state.scrapedMerchantData = data
    }
  },

  actions: {

    /**
     * Loads merchant data/deals from the APIs.
     */
    async loadMerchantData ({ commit, dispatch, rootState }, merchant) {
      await dispatch('loadActiveDomains', null, { root: true })

      const id = rootState.activeDomains.find(d => d.url === merchant).id

      return config.apiEndpoints.gsgMerchantDetail.getData({ id })
        .then(data => commit('updateCurrentMerchantData', data))
    },

    /**
     * Search for deals based on the provided query.
     */
    async searchAllDeals ({ commit, dispatch, rootState }, query) {
      await dispatch('loadActiveDomains', null, { root: true })

      const results = ListSearcher.getBestMatches(
        rootState.activeDomains.map(d => d.url), query
      )

      return commit('updateSearchResults', results)
    },

    /**
     * Scrapes the merchant info.
     */
    async scrapeMerchantInfo ({ commit }, merchant) {
      const domain = new DomainData(merchant)
      const url = domain.deriveOrigin()

      return !url ? null : new Scraper(url).getBasicInfo()
        .then(data => commit('updateScrapedMerchantData', data))
        // If the scraping was not successful, silently fail.
        .catch(() => {})
    }
  }
}
