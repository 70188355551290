export default {

  gb: {
    deals_newsletter: {
      body: {
        status: 'pending',
        merge_fields: {
          FORMSOURCE: 'merchant-deals-page'
        }
      },
      key: 'UK_NEWSLETTER_LIST_ID'
    },

    mobile_newsletter: {
      body: {
        status: 'pending',
        merge_fields: {
          FORMSOURCE: 'mobile-homepage'
        }
      },
      key: 'UK_NEWSLETTER_LIST_ID'
    },

    mobile_onboarding: {
      body: {
        status: 'subscribed'
      },
      key: 'UK_ONBOARDING_LIST_ID'
    },

    gener8_competition: {
      body: {
        status: 'subscribed',
        merge_fields: {
          FORMSOURCE: 'gener8-competition'
        }
      },
      key: 'UK_GENER8_LIST_ID'
    }
  },

  de: {
    deals_newsletter: {
      body: {
        status: 'pending',
        merge_fields: {
          FORMSOURCE: 'merchant-deals-page'
        }
      },
      key: 'DE_NEWSLETTER_LIST_ID'
    },

    mobile_newsletter: {
      body: {
        status: 'pending',
        merge_fields: {
          FORMSOURCE: 'mobile-homepage'
        }
      },
      key: 'DE_NEWSLETTER_LIST_ID'
    },

    mobile_onboarding: {
      body: {
        status: 'subscribed'
      },
      key: 'DE_ONBOARDING_LIST_ID'
    }
  }
}
