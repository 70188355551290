import Vue from 'vue'
import Uid from '@/core/services/Uid'
import Utm from '@/core/services/Utm'

export default async () => {
  if (crossDeviceSupport.mobileOs()) {
    return Promise.reject({ name: '' })
  }

  if (!crossDeviceSupport.supportedBrowser()) {
    return Promise.reject({ name: 'unsupportedbrowser' })
  }

  // Convert `$browser.name` to corresponding config webstore key
  const storeKey = name => name === 'edge-chromium' ? 'edge' : name

  location.href = [
    Utm.addQuery.bind(Utm),
    Uid.addQuery.bind(Uid)
  ].reduce(
    (carry, pipe) => pipe(carry),
    new URL(Vue.prototype.$config.extension.installationLinks[storeKey(Vue.prototype.$browser.name)])
  ).toString()

  // Wait for the hard redirect.
  return new Promise(() => null)
}
