<template>
  <div>
    <loading-bar></loading-bar>
    <transition name="fade">
      <router-view />
    </transition>
    <page-loading v-if="pageLoadingShown"></page-loading>
    <cookie-notice></cookie-notice>
    <install-popup></install-popup>
  </div>
</template>

<script>
import LoadingBar from './views/partials/LoadingBar'
import PageLoading from './views/partials/PageLoading'
import CookieNotice from './views/partials/CookieNotice'
import InstallPopup from './views/partials/InstallPopup'

export default {
  components: { CookieNotice, LoadingBar, PageLoading, InstallPopup },

  data () {
    return {
      pageLoadingShown: true
    }
  },

  mounted () {
    this.$dispatcher.touch('router.beforeRouteEnter').addListener(() => {
      this.pageLoadingShown = false
    })
  }
}
</script>

<style lang="sass">
@import assets/sass/app

.fade-enter-active, .fade-leave-active
  transition-property: opacity
  transition-duration: .3s
.fade-enter-active
  transition-delay: .3s
.fade-enter, .fade-leave-active
  opacity: 0
</style>
