<template>
  <main-layout>
    <main class="section is-medium">
      <div class="container">
        <div class="columns">
          <div class="column is-3 is-hidden-mobile">
            <p-fixed>
              <slot name="side"></slot>
            </p-fixed>
          </div>

          <div class="column is-3 is-hidden-tablet">
            <slot name="mobile"></slot>
          </div>

          <div class="column is-7 is-offset-1">
            <slot name="main"></slot>
          </div>
        </div>
      </div>
    </main>
  </main-layout>
</template>

<script>
export default {
  //
}
</script>
