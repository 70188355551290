import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en'
})

// Register translation assets against locale tags
i18n.setLocaleMessage('de', { ...require('@/assets/lang/de.json'), assets: require('@/assets/lang/de.assets.json') })
i18n.setLocaleMessage('en', { ...require('@/assets/lang/en.json'), assets: require('@/assets/lang/en.assets.json') })

export default i18n
