import { applySeo } from '@/seo'
import { env } from '@/bootstrap/registerGlobalHelpers'

/**
 * Insired by https://alligator.io/vuejs/vue-router-modify-head/
 */
export default async ({ to, from }) => {
  // Locale
  const { translation: locale } = to.params

  // Get the the localised SEO file
  const rules = require(`@/assets/lang/${locale}.seo.js`).rules

  // Get defaults and modifications from the SEO file
  const { default: def } = rules
  const seo = rules[to.name] || {}

  // Provide the SEO data
  const modifications = {
    title: seo.title || def.title,
    meta: {
      ...(def.meta || {}),
      ...(seo.meta || {}),
      url: location.href,
      title: seo.title || def.title,
      description: seo.description || def.description,
      'image:secure_url': (seo.meta || {}).image || def.meta.image,
      'twitter:title': seo.title || def.title,
      'twitter:image': (seo.meta || {}).image || def.meta.image,
      'twitter:description': seo.description || def.description,
      'fb:app_id': env('FB_APP_ID')
    }
  }

  return applySeo(modifications)
}
