let tag
let target
let script

const name = 'megatron'
const pointer = 'megatronObj'

module.exports = {

  /**
   * Assert global tracker object is set.
   *
   * @return {boolean}
   */
  _isset () {
    return Boolean(window[name])
  },

  /**
   * Define Megatron window fn and mount the local `mx.js` lib.
   *
   * @param {string} collect The collection endpoint
   * @param {string} library The Megatron source library
   * @return {void}
   */
  _load (collect, library) {
    // Exit if initialised
    if (this._isset()) return

    // Provision the global object
    window[pointer] = { name }
    window[name] = function () {
      (window[name].q = window[name].q || []).push(arguments)
    }
    window[name].d = collect

    // Configure and mount the source lib node
    tag = 'SCRIPT'
    script = document.createElement(tag)
    script.src = library
    script.async = true
    target = document.getElementsByTagName(tag)[0]
    target.parentNode.insertBefore(script, target)
  },

  /**
   * Initialise the Megatron global tracker object. Platform Id is required; set
   * optional Customer Hash to prevent it being defined arbitrarily...
   *
   * @param {string} collect The collection endpoint
   * @param {string} library The Megatron source library
   * @param {string} plid The unique platform (client) id
   * @param {string} [chid=''] The unique customer hash id
   */
  init (collect, library, plid, chid = '') {
    // Set up the tracker (define and mount...)
    this._load(collect, library)

    // Initialise the service with customer hash and platform ids
    Boolean(chid) && this.set('customerHash', chid)
    this.set('platformID', plid)
  },

  /**
   * Track a defined event.
   *
   * @param {string} category The event category
   * @param {string} [action] The event action
   * @param {...any} [rest] The event label, event value if required
   * @return {this}
   */
  event (category, action, ...rest) {
    this._isset() && window[name]('send', 'event', category, action, ...rest)

    return this
  },

  /**
   * Track a pageview.
   *
   * @return {this}
   */
  pageview () {
    this._isset() && window[name]('send', 'pageview')

    return this
  },

  /**
   * Set a tracker field.
   *
   * @param {string} key The key to set
   * @param {string} value The value to map from `key`
   * @return {this}
   */
  set (key, value) {
    this._isset() && window[name]('set', key, value)

    return this
  }
}
