import Vue from 'vue'
import config from '@/config'
import GA from 'universal-ga'
import Mixpanel from 'mixpanel-browser'
import MGTRX from '@/mgtrx'
import Cookie from '@/core/services/Cookie'
import Locator from './Locator'
import GTM from '@/GTM'

/**
 * Is true if analytics are disabled.
 *
 * @var {Boolean} _
 */
const _ = !config.analytics.active

export const gtm = {
  event (eventName) {
    if (!GTM.isInitialized) {
      return this
    }

    return _ ? this : GTM.event(eventName)
  },

  pageview (path) {
    if (!GTM.isInitialized) {
      return this
    }

    return _ ? this : GTM.pageView({
      event: 'virtualPageView',
      virtualPagePath: path
    })
  }
}

export const ga = {
  /**
   * Create a new GA tracker object.
   *
   * @param {string} trackingID
   * @param {any} options
   *
   * @return tracker to chain
   */
  create (trackingID, options) {
    return _ ? this : GA.create(trackingID, options)
  },

  /**
   * Track a GA event.
   *
   * @param {string} category
   * @param {string} action
   * @param {any} options
   *
   * @return tracker to chain
   */
  event (category, action, options) {
    return _ ? this : GA.event(category, action, options)
  },

  /**
   * Access GA tracker by name.
   *
   * @param {string} name
   *
   * @return tracker to chain
   */
  name (trackerName) {
    return _ ? this : GA.name(trackerName)
  },

  /**
   * Track a GA pageview.
   *
   * @param {string} path
   *
   * @return tracker to chain
   */
  pageview (path) {
    return _ ? this : GA.pageview(path)
  },

  /**
   * Set a GA tracker field.
   *
   * @param {string} key
   * @param {string} value
   *
   * @return the tracker to chain
   */
  set (key, value) {
    return _ ? this : GA.set(key, value)
  }
}

export const mt = {

  /**
   * Track an MGTRX event.
   *
   * @param {string} category
   * @param {string} action
   * @param {any} options
   *
   * @return tracker to chain
   */
  event (category, action, options) {
    return _ ? this : MGTRX.event(category, action, options)
  },

  /**
   * Track an MGTRX pageview.
   *
   * @return tracker to chain
   */
  pageview () {
    return _ ? this : MGTRX.pageview()
  },

  /**
   * Set an MGTRX tracker field.
   *
   * @param {string} key
   * @param {string} value
   *
   * @return the tracker to chain
   */
  set (key, value) {
    return _ ? this : MGTRX.set(key, value)
  }
}

export const mixpanel = {
  /**
   * Track a mixpanel event.
   *
   * @param {string} name
   * @param {any} properties
   */
  event (name, properties) {
    try {
      _ || Mixpanel.track(name, properties)
    } catch {
      //
    }
  },

  /**
   * Identifies a mixpanel user.
   *
   * @param {string} id
   */
  identify (id) {
    try {
      _ || Mixpanel.identify(id)
    } catch {
      //
    }
  }
}

/**
 * Generates a clickout link from the given data
 *
 * @param {object} data The given data of the retailer including:
 *   - logo
 *   - link
 *   - encryptedTrackingLink
 *   - id
 */
export const buildClickoutLink = data => {
  if (!data.encryptedTrackingLink) {
    return data.link
  }

  const country = Locator.country
  const { origin, pathname } = new URL(document.location)

  // Craft an URL object from the configurable base url.
  const url = new URL(`${config.clickoutLink.baseUrl.gb}/0`)
  const params = url.searchParams

  params.set('domain', config.cid[country])
  params.set('co_adblock', false)
  params.set('co_chash', Cookie.cookieBox().customerHash)
  params.set('co_dcountry', country)
  params.set('co_ds', `JOINPOUCH_${Vue.prototype.$browser.name}`)
  params.set('co_dtype', 'wl')
  params.set('co_page_type', 'WHAT_TO_USE_HERE')
  params.set('co_redu', data?.encryptedTrackingLink)
  params.set('co_retid', data?.id ?? '')
  params.set('co_tsp', String(Date.now()))
  params.set('co_vposition', 'SHOULD_WE_REMOVE_THIS')
  params.set('co_vtype', 'SHOULD_WE_REMOVE_THIS')
  params.set('co_url', `${origin}${pathname}`)
  params.set('co_uid', 'DONT_HAVE_AUTH_HERE')

  return url.toString()
}
