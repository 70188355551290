import { processTrackingDataState, knownParams } from '@/core/services/TrackingData'

const Utm = {

  /**
   * Scan and save any present utm params to the local storage.
   */
  scan () {
    // Check if we are on IE that does not have this functionality. We can then
    // just disable it since the extension is not for IE anyway.
    if (URL.constructor.name === 'Object') {
      return
    }

    const nextState = processTrackingDataState(
      {
        UTMs: getNormalizedUTMs(),
        referrer: document.referrer
      },
      getAvailabeUtmFromLS()
    )

    clearUTMFromLocalStorage()
    writeDataToLocalStorage(nextState)
  },

  /**
   * Return all present utm params (replaced with itm) as an object.
   *
   * @return {any}
   */
  getItms () {
    return knownParams.reduce((carry, param) => {
      return { ...carry, [utmToItm(param)]: localStorage[param] || '' }
    }, {})
  },

  /**
   * Modify a url to accept current UTM params.
   *
   * @param {URL} url
   * @return {URL}
   */
  addQuery (url) {
    const utm = this.get()

    Object.keys(utm).forEach(key => {
      if (utm[key]) {
        url.searchParams.set(key, utm[key])
      }
    })

    return url
  },

  /**
   * Get a url string and returns a URL which have a query of current UTM params as ITMs
   *
   * @param {urlStr} String - String URL
   * @return {URL} URL
   */
  addItmQuery (urlStr) {
    const url = new URL(urlStr)
    const itms = this.getItms()
    Object.keys(itms).forEach(i => {
      if (itms[i]) {
        url.searchParams.set(i, itms[i])
      }
    })
    return url
  },

  /**
   * Return all present utm params as an object.
   *
   * @return {any}
   */
  get () {
    return knownParams.reduce((carry, param) => {
      return { ...carry, [param]: localStorage[param] || '' }
    }, {})
  }
}

export default Utm

function writeDataToLocalStorage (data) {
  for (const param in data) {
    localStorage[param] = data[param]
  }
}

function clearUTMFromLocalStorage () {
  for (const param of knownParams) {
    // Remove only the utm prameters
    // https://cuponation.atlassian.net/browse/EXT-1968?focusedCommentId=361000
    if (param.startsWith('utm_')) {
      localStorage.removeItem(param)
    }
  }
}

function compareUtmParams (obj1, obj2) {
  for (const param of knownParams) {
    if (param.startsWith('utm_') && obj1[param] !== obj2[param]) {
      return false
    }
  }

  return true
}

function isEmpty (UTMsOrITMs) {
  return compareUtmParams(UTMsOrITMs, {})
}

/**
 * Return all present params of the url as an object.
 *
 * @param {function} whatToExtract: A function that use the given param to return the needed param we want to extract from the url
 * @returns
 */
function extractFromURL (whatToExtract) {
  const searchQuery = new URL(location.href.replace(/&amp;/g, '&')).searchParams

  return knownParams.reduce((carry, param) => {
    return searchQuery.get(whatToExtract(param))
      ? { ...carry, [param]: searchQuery.get(whatToExtract(param)) }
      : carry
  }, {})
}

/**
 * Return all present itm params of the url as an object.
 *
 * @return {any}
 */
function getURLItms () {
  return extractFromURL(param => utmToItm(param))
}

/**
 * Return all present utm params of the url as an object.
 *
 * @return {any}
 */
function getURLutms () {
  return extractFromURL(param => param)
}

function getNormalizedUTMs () {
  const urlItms = getURLItms()
  const urlUtms = getURLutms()

  if (!isEmpty(urlUtms) && !isEmpty(urlItms)) {
    throw new Error('Both UTMs and ITMs in URL, unsupported case')
  }

  if (!isEmpty(urlUtms)) {
    return urlUtms
  }

  if (!isEmpty(urlItms)) {
    return urlItms
  }

  return null
}

/**
 * Return only the availabe utm params from LocalStorage as an object.
 *
 * @return {any}
 */
function getAvailabeUtmFromLS () {
  const notEmptyParams = Object.entries(Utm.get()).filter(
    ([_key, value]) => value !== ''
  )
  return Object.fromEntries(notEmptyParams)
}

function utmToItm (param) {
  if (param.startsWith('utm_')) {
    const name = param.split('_')[1]
    return 'itm_' + name
  } else {
    return param
  }
}
