
export default {
  label: 'Influencer',
  name: 'influencer',
  folder: '/src/assets/influencers',
  create: true,
  slug: '{{link}}',
  identifier_name: 'link',
  fields: [
    {
      label: 'Link',
      name: 'link',
      required: true,
      widget: 'string',
      date_format: true
    },
    {
      label: 'Campaign',
      name: 'utm_campaign',
      required: true,
      widget: 'string'
    },
    {
      label: 'Source',
      name: 'utm_source',
      required: true,
      widget: 'select',
      options: ['blog', 'facebook', 'instagram', 'podcast', 'twitter', 'twitch', 'youtube']
    },
    {
      label: 'Content',
      name: 'utm_content',
      required: true,
      widget: 'select',
      options: ['afk', 'acast', 'covercomm', 'digitalvoices', 'fanbytes', 'frech', 'insight', 'pouch']
    },
    {
      label: 'Custom Influencer Landing Page',
      name: 'cilp',
      default: false,
      widget: 'boolean'
    },
    {
      label: 'Subtitle',
      name: 'subtitle',
      widget: 'string'
    },
    {
      label: 'Message',
      name: 'message',
      widget: 'string'
    },
    {
      label: 'Date',
      name: 'due',
      required: true,
      widget: 'date'
    }
  ]
}
