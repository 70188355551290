import influencers from '@/containers/influencers'
import Locator from '@/core/services/Locator'

export default async ({ to, from }) => {
  const locale = (Locator.localeFromPath() || Locator.locale)
  const influencer = to.params.influencer || 'podcast'
  const inf = influencers[influencer]

  const query = '' +
    `utm_source=${inf.utm_source
    }&utm_medium=${inf.utm_medium
    }&utm_content=${inf.utm_content
    }&utm_campaign=${inf.utm_campaign
    }`

  // Custom Influencer Landing Page...
  if (inf.cilp === true && !crossDeviceSupport.mobileOs()) {
    location.href = `/${locale}/cilp/${influencer}?${query}`
    return new Promise(() => {})
  }

  // Old Influencer Landing Page...
  location.href = `/${locale}?${query}`
  return new Promise(() => {})
}
