import md5 from 'md5'

export default {
  /**
   * Executes the callback at most once in specified amount of seconds.
   *
   * @param {number} seconds
   * @param {() => any} callback
   */
  in (seconds, callback) {
    const key = `once__${md5('' + callback)}`

    if (!localStorage[key] || localStorage[key] < Date.now()) {
      localStorage[key] = Date.now() + seconds * 1000

      callback()
    }
  }
}
