<template>
  <li :class="{ 'is-collapsible': collapsible, 'is-active': active }" @click="active = !active; active && $emit('change')">
    <slot></slot>
  </li>
</template>

<script>
export default {
  props: {
    collapsible: Boolean,
    startActive: Boolean
  },

  data () {
    return {
      active: this.startActive
    }
  }
}
</script>

<style lang="sass" scoped>
.is-active > span
  color: #589F5A !important
</style>
