<template>
  <div class="hero is-white is-large">
    <div class="hero-body has-text-centered">
      <div class="loading"></div>
      <span class="has-text-grey-light has-text-weight-semibold">
        {{ currentMessage }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentMessage: this.$t('partials.loading.hold'),
      messages: [
        this.$t('partials.loading.fetch'),
        this.$t('partials.loading.get')
      ]
    }
  },

  mounted () {
    setInterval(() => {
      this.changeMessage()
    }, 2000)
  },

  methods: {
    changeMessage () {
      const key = Math.floor(Math.random() * this.messages.length)

      this.currentMessage = this.messages[key]
    }
  }
}
</script>

<style lang="sass" scoped>
@keyframes spin
  0%
      transform: rotate(0deg)
  100%
      transform: rotate(360deg)

.loading
  animation: spin 1s linear infinite
  border-radius: 50%
  border: 3px solid #ff6441
  border-top: 3px solid transparent
  height: 80px
  margin: 0 auto 30px
  width: 80px

</style>
