<template>
  <div>

    <!-- Minimal Nav Section -->
    <nav class="nav" aria-label="main navigation">
      <div class="container">
        <div class="nav-cluster">
          <div class="nav-menu">

            <router-link
              class="nav-item"
              :to="{ name: 'home' }"
            >
              <img v-lazy="`$/images/logo.svg`" alt="Pouch">
            </router-link>

          </div>

          <div class="nav-spacer"></div>

          <div
            class="nav-item is-hidden-touch"
            v-only-extension-fit
            v-hidden-with-extension
          >
            <add-to-browser
              v-t="`buttons.get-pouch`"
              position="ArticleDesktopView"
            ></add-to-browser>
          </div>

        </div>
      </div>
    </nav>

    <!-- Main View -->
    <slot></slot>

    <!-- Minimal Foote Section -->
    <footer class="has-padding-4">
      <div class="container columns is-desktop">

        <div class="
          column
          is-half-desktop
          has-text-left
          has-text-grey-light
        ">
          <span class="has-padding-2">
            &copy;
            <span v-t="{
              path: 'partials.footer.copyright',
              args: { year }
            }"
            ></span>
          </span>
        </div>

        <div class="
          column
          is-half-desktop
          has-text-right-desktop
        ">
          <router-link
            :to="{ name: 'imprint' }"
            class="is-inline-desktop has-padding-2"
            v-t="`partials.footer.imprint`"
          ></router-link>
          <router-link
            :to="{ name: 'privacypolicy' }"
            class="is-inline-desktop has-padding-2"
            v-t="`links.privacy-policy`"
          ></router-link>
          <router-link
            :to="{ name: 'contact' }"
            class="is-inline-desktop has-padding-2"
            v-t="`links.contact-us`"
          ></router-link>
        </div>

      </div>
    </footer>

  </div>
</template>

<script>
import AddToBrowser from '@/views/partials/AddToBrowser'

export default {
  components: { AddToBrowser },

  computed: {
    year () {
      return new Date().getFullYear()
    }
  }

}
</script>

<style lang="sass" scoped>
footer
  margin: 0 3rem
</style>
