<template>
  <div
    :class="{ 'field': true, 'is-horizontal': horizontal }"
    :style="{ 'margin-top': doubleSpaced ? '40px' : spaced ? '20px' : '0' }"
  >
    <div :class="{ 'field-label': horizontal }">
      <slot name="label"></slot>
    </div>

    <div class="field-body">
      <div class="field" :class="{ 'has-addons': addons }">
        <slot></slot>
      </div>
    </div>

    <slot name="errors">
      <span class="help is-danger" v-for="(error, key) in errors" :key="key">
        <i class="fas fa-exclamation-circle"></i>
        &nbsp;
        <span v-t="error"></span>
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    horizontal: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    spaced: Boolean,
    doubleSpaced: Boolean,
    addons: Boolean
  }
}
</script>
