import store from '@/store'
import FlashMessages from '@/core/services/FlashMessages'

export default class {
  /**
   * Class constructor.
   */
  constructor () {
    this.$store = store
    this.$messages = FlashMessages
  }

  /**
   * 404 response shortcut.
   */
  notFound () {
    return {
      path: '/not-found'
    }
  }
}
