import Vue from 'vue'

import DealLayout from '@/views/layouts/Deal'
import MainLayout from '@/views/layouts/Main'
import PageLayout from '@/views/layouts/Page'
import EmptyLayout from '@/views/layouts/Empty'
import AccountLayout from '@/views/layouts/Account'
import ArticleLayout from '@/views/layouts/Article'
import OnboardingLayout from '@/views/layouts/Onboarding'
import OnboardingMobileLayout from '@/views/layouts/OnboardingMobile'

import Form from '@/views/components/Form'
import List from '@/views/components/List'
import Field from '@/views/components/Field'
import Fixed from '@/views/components/Fixed'
import Input from '@/views/components/Input'
import Label from '@/views/components/Label'
import Lever from '@/views/components/Lever'
import Modal from '@/views/components/Modal'
import Button from '@/views/components/Button'
import Slider from '@/views/components/Slider'
import Checkbox from '@/views/components/Checkbox'
import Tickbox from '@/views/components/Tickbox'
import ListItem from '@/views/components/ListItem'
import Textarea from '@/views/components/Textarea'
import Dropdown from '@/views/components/Dropdown'
import Tooltip from '@/views/components/Tooltip'
import Tabs from '@/views/components/Tabs'
import TabItem from '@/views/components/TabItem'
import Rating from '@/views/components/Rating'

/**
 * Registering certain reused components globally.
 */

Vue.component('deal-layout', DealLayout)
Vue.component('main-layout', MainLayout)
Vue.component('page-layout', PageLayout)
Vue.component('empty-layout', EmptyLayout)
Vue.component('account-layout', AccountLayout)
Vue.component('article-layout', ArticleLayout)
Vue.component('onboarding-layout', OnboardingLayout)
Vue.component('onboarding-mobile-layout', OnboardingMobileLayout)

Vue.component('p-form', Form)
Vue.component('p-list', List)
Vue.component('p-field', Field)
Vue.component('p-fixed', Fixed)
Vue.component('p-input', Input)
Vue.component('p-label', Label)
Vue.component('p-lever', Lever)
Vue.component('p-modal', Modal)
Vue.component('p-button', Button)
Vue.component('p-slider', Slider)
Vue.component('p-checkbox', Checkbox)
Vue.component('p-tickbox', Tickbox)
Vue.component('p-textarea', Textarea)
Vue.component('p-list-item', ListItem)
Vue.component('p-dropdown', Dropdown)
Vue.component('p-tooltip', Tooltip)
Vue.component('p-tabs', Tabs)
Vue.component('p-tab-item', TabItem)
Vue.component('p-rating', Rating)
