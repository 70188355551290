
/**
 * Await messages from the extension.
 */
addEventListener('message', (event) => {
  if (event.data.action === 'pouch:isInstalled') {
    localStorage.pouchInstalled = event.data.value
  }

  if (event.data.action === 'pouch:userIsSignedIn') {
    sessionStorage.extensionSignedIn = true
  }
})
