import config from '@/config'
import Amplify, { Auth } from 'aws-amplify'

export default {
  install (Vue) {
    Amplify.configure({
      Auth: {
        region: config.auth.region,
        userPoolId: config.auth.cognitoUserPoolId,
        userPoolWebClientId: config.auth.cognitoClientId,
        cookieStorage: {
          domain: config.auth.cognitoCookieStorageDomain,
          secure: config.auth.cognitoCookieStorageSecure
        },
        mandatorySignIn: false,
        authenticationFlowType: 'USER_SRP_AUTH',
        oauth: {
          domain: [config.auth.cognitoDomain, 'joinpouch.com'],
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [config.auth.cognitoSignInRedirectUrl, `${process.env.VUE_APP_ACCOUNT_URL}/en-gb/pouch-points/overview`],
          redirectSignOut: [config.auth.cognitoSignOutRedirectUrl, 'https://joinpouch.com/en-gb/login'],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    })
    Vue.prototype.$auth = Auth
  }
}
