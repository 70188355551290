
export default {

  namespaced: true,

  state: {
    currentPost: {},
    posts: []
  },

  mutations: {
    updatePosts (state, posts) {
      state.posts = posts
    },

    updateCurrentPost (state, post) {
      state.currentPost = post
    }
  },

  actions: {
    /**
     * Load all blog posts.
     */
    async loadPosts ({ commit }) {
      return commit('updatePosts', require('@/containers/posts.json'))
    },

    /**
     * Loads the currently active blog post.
     */
    async loadCurrentPost ({ commit }, slug) {
      const post = require('@/containers/posts.json').find(post => post.slug === slug)

      return post ? commit('updateCurrentPost', post) : Promise.reject()
    }
  }
}
