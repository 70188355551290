import Vue from 'vue'
import Vuex from 'vuex'

import blog from './blog'
import crlp from './crlp'
import deals from './deals'
import points from './points'
import account from './account'
import article from './article'
import onboarding from './onboarding'
import giftcards from './giftcards'

import attachEvents from './events'

import i18n from '@/i18n'
import config from '@/config'
import Logger from '@/core/services/Logger'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    blog,
    crlp,
    deals,
    points,
    account,
    article,
    onboarding,
    giftcards
  },

  state: {
    activeDomains: [],
    faq: [],
    tos: [],
    featuredVouchers: [],
    featuredRetailers: [],
    installPopupShown: false,
    publications: [],
    testimonials: [],
    userLoginInfo: null
  },

  getters: {
    /**
     * Returns an index of the middle testimonial on desktop or first testimonial on mobile.
     *
     * @param {any} state
     *
     * @return {Number}
     */
    activeTestimonial: (state) => {
      return crossDeviceSupport.mobileOs()
        ? 0
        : state.testimonials.findIndex(i => i.active)
    },

    featuredPublications: state => state.publications.filter(p => p.featured),
    installPopupShown: state => state.installPopupShown
  },

  mutations: {
    updateActiveDomains (state, data) {
      state.activeDomains = data
    },

    updateFeaturedVouchers (state, data) {
      state.featuredVouchers = data
    },

    updatePublications (state, publications) {
      state.publications = publications
    },

    updateTestimonials (state, testimonials) {
      state.testimonials = testimonials
    },

    updateFeaturedRetailers (state, featuredRetailers) {
      state.featuredRetailers = featuredRetailers
    },

    updateFaq (state, faq) {
      state.faq = faq
    },

    updateTos (state, tos) {
      state.tos = tos
    },

    updateUserInfo (state, userLoginData) {
      state.userLoginInfo = userLoginData
    },

    updateInfluencer (state, influencer) {
      state.influencer = influencer
    }
  },

  actions: {
    /**
     * Loads all active domains.
     */
    async loadActiveDomains ({ commit }) {
      return config.apiEndpoints.gsgActiveDomains.getData()
        .then(data => commit('updateActiveDomains', data))
    },

    /**
     * Loads the top 20 deals from the APIs.
     */
    async loadFeaturedVouchers ({ commit, dispatch, state }) {
      await dispatch('loadActiveDomains')

      const data = await config.apiEndpoints.gsgFeaturedVouchers.getData()

      data.forEach(voucher => {
        voucher.merchant.logo = state.activeDomains
          .find(domain => domain.id === voucher.id_merchant).merchant.logo
      })

      return commit('updateFeaturedVouchers', data)
    },

    async loadPublications ({ commit }) {
      return commit('updatePublications', require('@/containers/' + i18n.locale + '/publications.json'))
    },

    async loadTestimonials ({ commit }) {
      return commit('updateTestimonials', require('@/containers/' + i18n.locale + '/testimonials.json'))
    },

    async loadFeaturedRetailers ({ commit }) {
      return commit('updateFeaturedRetailers', require('@/containers/' + i18n.locale + '/featuredRetailers.json'))
    },

    async loadFaq ({ commit }) {
      return commit('updateFaq', require('@/containers/' + i18n.locale + '/faq.json'))
    },

    async loadTos ({ commit }) {
      return commit('updateTos', require('@/containers/' + i18n.locale + '/termsAndConditions.json'))
    },

    async loadLoginUserInfo ({ commit }, userObj) {
      return commit('updateUserInfo', userObj)
    },

    async activateTestimonial ({ state, commit }, key) {
      const testimonials = state.testimonials.map((t, i) => ({ ...t, active: key === i }))

      return commit('updateTestimonials', testimonials)
    },

    async loadInfluencer ({ commit }, influencer) {
      return commit('updateInfluencer', influencer)
    },

    /**
     * Subimits to Mailchimp
     */
    postDataToMailchimp (_, data) {
      return config.apiEndpoints.mailchimp.post({}, data)
        .catch(e => {
          Logger.error(e)
          throw e
        })
    },

    /**
     * Toggle the insall popup.
     */
    toggleInstallPopup ({ state }, open) {
      state.installPopupShown = open
    }
  }
})

attachEvents(store)

export default store
