<template>
  <div class="search" :class="{ 'is-mobile': mobile, 'is-active': active }">
    <form class="search-form" @submit.prevent="onFormSubmission">
      <div class="search-glass">
        <i class="fas fa-fw fa-search fa-lg"></i>
      </div>
      <input
        class="search-input"
        type="text"
        :placeholder="$t('partials.search.type-a-retailer')"
        v-model="query"
        @blur="suggestionsShown = false; suggestionsLoading = false"
      >
      <div class="search-close" @click="$emit('close')">
        <i class="fas fa-fw fa-times fa-lg"></i>
      </div>
    </form>

    <ul class="search-suggestions" v-if="suggestionsLoading">
      <li><button class="button is-text is-loading"></button></li>
    </ul>

    <ul
      class="search-suggestions"
      v-if="!suggestions.merchants.length && !suggestionsLoading && !!query"
    >
      <li v-t="`partials.search.sorry`"></li>
    </ul>

    <ul
      class="search-suggestions"
      v-if="suggestions.merchants.length && !suggestionsLoading && suggestionsShown"
    >
      <li class="static" v-if="suggestions.merchants.length" v-t="`partials.search.retailers`"></li>
      <li v-for="merchant in suggestions.merchants" :key="merchant" @mousedown="redirectToMerchant(merchant)">
        {{ merchant | parseMerchantName}}
      </li>
    </ul>

    <div class="search-cover"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    mobile: Boolean,
    active: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      query: '',
      typingTimeout: null,
      suggestionsLoading: false,
      suggestionsShown: false
    }
  },

  computed: {
    ...mapState({
      suggestions: state => state.deals.searchResults
    })
  },

  filters: {
    parseMerchantName (value) {
      return value
    }
  },

  watch: {
    query (value) {
      this.suggestionsLoading = value.length > 0

      this.suggestionsShown = value.length > 2

      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout)
      }

      this.typingTimeout = setTimeout(() => {
        this.findSuggestions()
      }, 300)
    }
  },

  mounted () {
    this.suggestionsShown = false
  },

  methods: {
    findSuggestions () {
      if (this.query.length < 3) {
        return
      }

      this.suggestionsShown = true

      this.$store.dispatch('deals/searchAllDeals', this.query)
        .then(() => this.suggestionsLoading = false)
    },

    onFormSubmission () {
      const merchant = this.suggestions.merchants[0]

      // Fire a global event that the search form was submitted.
      this.$dispatcher.fire('app.searchFormSubmitted', {
        route: this.$route,
        query: this.query,
        target: merchant,
        success: !!merchant
      })

      if (merchant) {
        this.$router.push({
          name: 'merchant',
          params: { merchant }
        })
      }
    },

    redirectToMerchant (merchant) {
      // Fire a global event that the search suggestion was clicked.
      this.$dispatcher.fire('app.searchSuggestionClicked', {
        route: this.$route,
        query: this.query,
        target: merchant
      })

      this.$router.push({
        name: 'merchant',
        params: { merchant }
      })
    }
  }
}
</script>
