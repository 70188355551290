import Controller from './Controller'

export default class DealsController extends Controller {
  /**
   * Redirect to home or onboarding - TEMP fix while deals content legal issues
   * are resolved. This controller will inspect the `to` route param and if it
   * includes the `?installed` query, will redirect to `/onboarding` else will
   * redirect to the home page until further notice.
   *
   * TODO: We should really check locale here and send all DE users to `/` with
   * `#retailers` fragment but as the onboarding var b will be rolled out with
   * localisation asap we can avoid for now...
   */
  async redirect ({ to, from }) {
    if ('installed' in to.query) {
      return '/onboarding'
    }

    return '/'
  }

  /**
   * Prepares a basic state of the deals page.
   */
  async index ({ to, from }) {
    return this.$store.dispatch('loadFeaturedVouchers')
  }

  /**
   * Prepares a state of a retailer landing page.
   */
  async merchant ({ to, from }) {
    this.$store.dispatch('loadFeaturedVouchers')
    this.$store.dispatch('deals/scrapeMerchantInfo', to.params.merchant)

    return this.$store.dispatch('deals/loadMerchantData', to.params.merchant)
      .catch(() => {
        this.$messages.danger('The retailer you requested was not found.')

        return '/deals'
      })
  }

  /**
   * Prepares a state of the retailer list page.
   */
  async merchants ({ to, from }) {
    return this.$store.dispatch('loadActiveDomains')
  }
}
