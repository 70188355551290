<template>
  <button :disabled="disabled"
          :class="{
            'button': true,

            'is-default': ordinary,
            'is-primary': primary,
            'is-secondary': secondary,
            'is-tertiary': tertiary,
            'is-white': white,
            'is-facebook': facebook,
            'is-messenger': messenger,
            'is-text': text,
            'is-danger': danger,

            'is-loading': loading,

            'is-small': small,
            'is-medium': medium,
            'is-large': large,
            'is-fullwidth': fullwidth,

            'has-text-weight-semibold': bold
          }"
          v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    primary: Boolean,
    secondary: Boolean,
    tertiary: Boolean,
    white: Boolean,
    facebook: Boolean,
    messenger: Boolean,
    text: Boolean,
    danger: Boolean,

    loading: Boolean,
    disabled: Boolean,

    small: Boolean,
    medium: Boolean,
    large: Boolean,
    fullwidth: Boolean,

    bold: Boolean
  },

  computed: {
    ordinary () {
      return !this.primary && !this.secondary && !this.tertiary && !this.white && !this.facebook && !this.messenger && !this.text && !this.danger
    }
  }
}
</script>
