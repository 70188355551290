<template>
  <div class="install-popup" :class="{ 'is-active': installPopupShown }">
    <div class="conatiner">
      <div class="columns">
        <div class="column is-4 is-offset-2">
          <div class="install-popup-instructions content">
            <p class="title is-3">
              <span class="has-text-weight-normal" v-t="`partials.install-popup.instruction.title`"></span>
              <span v-t="`partials.install-popup.instruction.bold-title`"></span>
            </p>
            <blockquote cite="https://www.youtube.com/watch?v=mx-dpphi3ZU&lc=Ugx7PuuZ9_gLRSro_il4AaABAg" class="has-text-grey">
              <span></span>
              <h3 v-t="`partials.install-popup.instruction.comment`"></h3>
            </blockquote>
            <footer class="has-text-grey">
              <span v-t="`partials.install-popup.instruction.comment-author`"></span>&nbsp;
              <cite v-t="`partials.install-popup.instruction.comment-cite`"></cite>
            </footer>
          </div>
        </div>

         <div class="column is-5 is-offset-1">
          <div class="install-popup-benefits content">
            <span class="delete-button">
              <i class="fas fa-times icon is-close" @click="toggleInstallPopup(false)"></i>
            </span>
            <p class="title is-3" v-t="`partials.install-popup.title`"></p>

            <ul>
              <li><i class="fas fa-check has-text-success"></i> <span v-t="`partials.install-popup.list.never`"></span></li>
              <li><i class="fas fa-check has-text-success"></i> <span v-t="`partials.install-popup.list.apply`"></span></li>
              <li><i class="fas fa-check has-text-success"></i> <span v-t="`partials.install-popup.list.access`"></span></li>
            </ul>

            <hr class="is-short has-background-grey-lighter">

            <p class="has-text-grey-light">
              <i18n path="home.rating.label" tag="span">
                <template #value>
                  <strong v-t="`home.rating.value`"></strong>
                </template>
                <template #max>
                  <strong>5</strong>
                </template>
              </i18n>
              <br>
              <i class="fas fa-star has-text-warning"></i>
              <i class="fas fa-star has-text-warning"></i>
              <i class="fas fa-star has-text-warning"></i>
              <i class="fas fa-star has-text-warning"></i>
              <i class="fas fa-star-half-alt has-text-warning"></i>
            </p>

            <p>
              <add-to-browser position="InstallPopup">
                <i class="fas fa-arrow-circle-down"></i>&nbsp;
                <span v-t="`buttons.add-to-chrome`"></span>
              </add-to-browser>
              &nbsp;
              <p-button text bold
                @click="toggleInstallPopup(false)"
                v-t="`buttons.close`"
              ></p-button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddToBrowser from './AddToBrowser'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { AddToBrowser },
  computed: mapGetters(['installPopupShown']),
  methods: mapActions(['toggleInstallPopup'])
}
</script>
